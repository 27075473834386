import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { takeUntil, Subject } from "rxjs";
import { vendorDetails } from '@/model/vendorDetails';


@Component({
  selector: 'app-hospital-vendor-details',
  templateUrl: './hospital-vendor-details.component.html',
  styleUrls: ['./hospital-vendor-details.component.scss']
})
export class HospitalVendorDetailsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  getVendors: vendorDetails[] = []
  finalVendorDetails: vendorDetails[] = []
  searchText: any;
  vendorName: string[] = []
  vendorFilter: string | null = null;

  constructor(private appservice: AppService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getVendorDetails();
  }

  getVendorDetails() {
    this.spinner.show();
    this.appservice.getVendorDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.getVendors = data.sort((a, b) => a.vendorName.localeCompare(b.vendorName));
        const vendors = new Set(this.getVendors.map(res => res.vendorName))
        this.vendorName = Array.from(vendors)
        this.finalVendorDetails = this.getVendors
      }, (error: any) => {
        this.spinner.hide();
        this.toastr.error("Something went wrong , Please try again later....")
        console.log("Something Went Wrong::", error);
      })
  }

  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    switch (filterType) {
      case 'vendor':
        this.vendorFilter = value !== 'Vendor Name' ? value : null;
        break;
    }
    this.applyFilters();
  }

  applyFilters() {
    const vendorFiltered = this.vendorFilter ? this.getVendors.filter(item => item.vendorName === this.vendorFilter) : this.getVendors;
    this.finalVendorDetails = vendorFiltered
  }

}