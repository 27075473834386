<app-amdds-header></app-amdds-header>

<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Daily Stock Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/StockUpload"><b>Stock Upload</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/SameStockDetails"><b>Unsold Stock Details</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <div class="row py-5"></div>
  <h2 class="bold text-center mt-3">Last One Week Unsold Stock Details</h2>
  <div class="container mt-2">
    <div class="row">
      <div class="col-4 form-floating">
      </div>
      <div class="col-3"></div>
      <div class="col-4 mb-2 mt-3 ms-2">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>

    <div class="container">
      <div class="table-responsive">
        <table class="table">
          <thead class="rounded">
            <tr class="sticky-top">
              <th>No</th>
              <th>Description</th>
              <th>Total Stock</th>
              <th>Unit</th>
              <th>MRP Rate</th>
              <th>Bacth Number</th>
              <th>Expiry Date</th>
              <th>Vendor Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stock of dailyStockDetails| filter: searchText; let i = index">
              <td>{{i + 1}}</td>
              <td>{{stock.description}}</td>
              <td>{{stock.totalStock}}</td>
              <td>{{stock.unit}}</td>
              <td>{{stock.mrpRate}}</td>
              <td>{{stock.batchNumber}}</td>
              <td>{{stock.expiryDate}}</td>
              <td>{{stock.vendorName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <ngx-spinner>
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </ngx-spinner>

  <app-amdds-footer></app-amdds-footer>