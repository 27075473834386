import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ThemeService } from '@services/themes.service';


@Component({
  selector: 'app-amdds-header',
  templateUrl: './amdds-header.component.html',
  styleUrls: ['./amdds-header.component.scss']
})
export class AmddsHeaderComponent implements OnInit {
  isShow: boolean = false;
  isDarkMode = false;

  constructor(private router: Router, private appService: AppService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.isDarkMode = this.themeService.isDarkMode;
    this.themeService.darkMode$.subscribe((isDarkMode: boolean) => {
      this.isDarkMode = isDarkMode;
    });
    if (localStorage.getItem('userType') == "") {
      this.isShow = false;
    } else if (localStorage.getItem('userType') == "ADMIN" || localStorage.getItem('userType') == "USER") {
      this.isShow = true;
    }
  }

  reload() {
    window.location.reload();
  }

  logout() {
    this.appService.logout()
  }
  home() {
    this.router.navigate(["/dash"]);
  }

  toggleTheme() {
    this.themeService.toggleDarkMode(!this.isDarkMode);
  }
}
