import { StockPurchaseDetails } from "@/model/stockPurchaseDetails";
import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-overall-vendor-payment-details',
  templateUrl: './overall-vendor-payment-details.component.html',
  styleUrls: ['./overall-vendor-payment-details.component.scss']
})

export class OverAllVendorPaymentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  stockDetails: StockPurchaseDetails[] = [];
  stock: StockPurchaseDetails[] = [];
  daysDifference: number[] = []
  adminApproval: string[] = [];
  bankType: string[] = [];
  names: string[] = [];
  uniqueDates: string[] = [];
  vendorName: string[] = [];
  entrydata: string[] = [];
  UniqueData: string[] = [];
  adminStatus: string[] = [];
  vendorFilter: string | null = null;
  dateFilter: string | null = null;
  statusFilter: string | null = null;
  approvelFilter: string | null = null;
  agingDetails: number | null = null;
  bankTypeFilter: string | null = null;
  selectedAgingValues: number[] = [];
  multiClick: StockPurchaseDetails[] = []
  disabledStatus: { [stockId: number]: boolean } = {};
  isUpgradeButtonDisabled: boolean = false;
  searchText: any;
  ;

  inputValues: { transactionId: string, stockId: number, payDate: string }[] = [];
  constructor(private appservice: AppService, private spinner: NgxSpinnerService, private tostar: ToastrService) {
  }


  ngOnInit() {
    this.GetStock()
  }


  GetStock() {
    this.spinner.show()
    this.appservice
      .getAgingDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.stockDetails = data.filter(res => res.date != null && res.adminStatus === "Agree" && res.vendor !== "VEND-0070" && res.deleteYn.toLowerCase() === 'no')
        // this.stockDetails = data
        var localdata: number[] = []
        for (let age of this.stockDetails) {
          localdata.push(age.aging)
        }
        this.spinner.hide()
        const currentAging = new Set(this.stockDetails.map(item => item.aging));
        this.daysDifference = Array.from(currentAging).filter(aging => aging !== null)
        const uniqueSet = new Set(this.stockDetails.map(item => item.payDate));
        this.uniqueDates = Array.from(uniqueSet).filter(date => date !== null && date !== "").sort((a, b) => a.localeCompare(b));
        const vendorSet = new Set(this.stockDetails.map(item => item.vendorName));
        this.vendorName = Array.from(vendorSet).filter(vendor => vendor !== null && vendor !== "").sort((a, b) => a.localeCompare(b));
        const adminstatus = new Set(this.stockDetails.map(item => item.doctorStatus));
        this.adminStatus = Array.from(adminstatus).filter(status => status !== null && status !== "");
        const adminApprovalSet = new Set(this.stockDetails.map(item => item.paymentStatus));
        this.adminApproval = Array.from(adminApprovalSet).filter(approval => approval !== null && approval !== "");
        const uniquebankTypes = new Set(this.stockDetails.map(item => item.vendorDetails?.bankName));
        this.bankType = Array.from(uniquebankTypes).filter(banktypes => banktypes !== null && banktypes !== "")
        this.stock = this.stockDetails;
        this.applyFilters()
      }, (err: any) => {
        console.log("Something Went Wrong")
        this.spinner.hide();
        this.tostar.error("Something went wrong , Please try again later....")
      })
  }



  updateStock(event: any, filterType: string) {
    const value = event.target.value;
    switch (filterType) {
      case 'vendor':
        this.vendorFilter = value !== 'Select vendor Name' ? value : null;
        break;
      case 'date':
        this.dateFilter = value !== 'Select Paid Date' ? value : null;
        break;
      case 'status':
        this.statusFilter = value !== 'Select Approval' ? value : null;
        break;
      case 'approval':
        this.approvelFilter = value !== 'Select Payment Status' ? value : null;
        break;
      case 'age':
        this.agingDetails = value !== 'Select Option' ? value : null;
        break;
      case 'bankType':
        this.bankTypeFilter = value !== 'Select Bank Type' ? value : null;
      default:
        break;
    }

    this.applyFilters();
  }

  applyFilters() {
    const vendorFiltered = this.vendorFilter ? this.stockDetails.filter(item => item.vendorName === this.vendorFilter) : this.stockDetails;
    const statusFiltered = this.approvelFilter ? vendorFiltered.filter(item => item.paymentStatus === this.approvelFilter) : vendorFiltered;
    const dateFiltered = this.dateFilter ? statusFiltered.filter(item => item.payDate === this.dateFilter) : statusFiltered;

    if (this.selectedAgingValues.length > 0) {
      this.stock = dateFiltered.filter(item => this.selectedAgingValues.includes(item.aging))
    }
    else {
      this.stock = dateFiltered;
    }
  }


}