import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';
import { AppService } from "@services/app.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PatientCount } from "@/model/PatientCount";
import { FormGroup, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DoctorName } from "@/model/DoctorName";
import { dailyDoctors } from "@/model/dailyDoctors";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
    selector: "app-patient-count-details",
    templateUrl: "./patient-count-details.component.html",
    styleUrls: ["./patient-count-details.component.scss"],
})

export class PatientCountDetailsComponent implements OnInit {
    showPopUp = false;
    Patient: PatientCount[] = [];
    DoctorID: string[] = []
    multiIncidental: dailyDoctors[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    inputs: string[] = []
    doctorName: String[] = [];
    getAllDoctorName: DoctorName[] = [];
    getOPPatients: PatientCount[] = []
    doctorNames: string[] = [];
    localdate: string = ""
    PatientCount: number = 0;
    cureentPatient: number = 0;
    totalcount: string = "";
    totalPatientCount: string = "";
    total: number = 0
    currentDate: string;
    doctorDetails = false;
    OpPaientDetails: PatientCount[] = [];
    Pdate: string = ""
    icon = true;
    totalInPatientCount: number = 0
    multpleDoctors = true;
    DailyDate: dailyDoctors[] = [];

    constructor(private appService: AppService,
        private spinner: NgxSpinnerService) {
        const today = new Date();
        this.currentDate = today.toISOString().substring(0, 10);
    }

    doctor = new UntypedFormGroup({
        doctorName: new UntypedFormControl('', Validators.required),
        createdBy: new UntypedFormControl('', Validators.required),
        createdDate: new UntypedFormControl('', Validators.required),
    })
    Error = {
        doctorName: ''
    }

    valid() {
        this.Error.doctorName = ""
        let hasError = false;
        if (this.doctor.get('doctorName')?.invalid) {
            this.Error.doctorName = "Doctor Name is required";
            hasError = true;
        }
        if (!hasError) {
            this.saveName()
        }

    }


    saveName() {
        this.appService
            .saveNewDoctor(this.doctor.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                // this.toastr.success('Doctor name added');
                this.getAllName()
                this.close()
            })
    }


    PatientForm = new UntypedFormGroup({
        amFormID: new UntypedFormControl('', Validators.required),
        amDoctorName: new UntypedFormControl('', Validators.required),
        amFormDate: new UntypedFormControl('', Validators.required),
        // BMP :new UntypedFormControl('', Validators.required),
        // doctor :new UntypedFormControl('', Validators.required),
        amPatientCount: new UntypedFormControl('', Validators.required),
        plus: new UntypedFormControl('', Validators.required),
        amTotalInPatient: new UntypedFormControl('', Validators.required),
        amTotalCases: new UntypedFormControl('', Validators.required),
        amGeneralRoom1: new UntypedFormControl('', Validators.required),
        amGeneralRoom2: new UntypedFormControl('', Validators.required),
        amGeneralRoom3: new UntypedFormControl('', Validators.required),
        amGeneralRoom4: new UntypedFormControl('', Validators.required),
        amGeneralRoom5: new UntypedFormControl('', Validators.required),
        amGeneralRoom6: new UntypedFormControl('', Validators.required),
        amRoom101: new UntypedFormControl('', Validators.required),
        amRoom102: new UntypedFormControl('', Validators.required),
        amRoom104: new UntypedFormControl('', Validators.required),
        amSurgeryMinorPatient: new UntypedFormControl('', Validators.required),
        amSurgeryMajorPatient: new UntypedFormControl('', Validators.required),
        amComments: new UntypedFormControl('', Validators.required),
        opDoctors: new UntypedFormControl('', Validators.required),
        activeYN: new UntypedFormControl('', Validators.required),
        deleteYN: new UntypedFormControl('', Validators.required),
    })
    public PatientFormError = {
        amComments: "",
        MajorSurgery: ""
    }

    getDate() {
        this.currentDate = this.PatientForm.get('amFormDate')?.value
        this.icon = true
        this.doctorDetails = false
        this.multpleDoctors = true
        this.multiIncidental = []
        this.getOPPatient()
        this.getDateFetch()
    }

    onSubmit() {
        this.validation()
    }

    validation() {
        this.PatientFormError.MajorSurgery = " "
        let hasError = false;
        if (!hasError) {
            this.addBulk()
        }
    }

    ngOnInit(): void {
        this.getDoctor()
        this.getAllName()
        this.PatientForm.controls['amDoctorName'].setValue("Dr.BMP")
        this.PatientForm.controls['amFormDate'].setValue(this.currentDate)
    }

    open() {
        this.showPopUp = true;
    }

    close() {
        this.showPopUp = false;
    }
    cancel() {
        window.location.reload()
    }
    getDoctor() {
        this.appService
            .getDoctorId()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.Patient = data;
                var localDoctorId: string[] = []
                for (let id of this.Patient) {
                    localDoctorId.push(id.amFormID)
                }
                //@ts-ignore
                this.DoctorID = localDoctorId
            })
    }

    addInput() {
        const multiIncidental: dailyDoctors = new dailyDoctors();
        this.multiIncidental.push(multiIncidental);
    }

    multiValue(multiIncidental: dailyDoctors, event: any) {
        multiIncidental.amFormID = this.DoctorID[0]
        multiIncidental.amFormDate = this.currentDate
        const index = this.multiIncidental.indexOf(multiIncidental);
        multiIncidental.amDoctorName = event.target.value;
        this.doctorName = event.target.value;
        this.multiIncidental[index] = multiIncidental;
    }

    multiinput(multiIncidental: dailyDoctors, event: any) {
        multiIncidental.amFormID = this.DoctorID[0]
        multiIncidental.amFormDate = this.currentDate
        const index = this.multiIncidental.indexOf(multiIncidental);
        multiIncidental.amPatientCount = event.target.value;
        this.PatientCount = event.target.value;
        this.multiIncidental[index] = multiIncidental;
        //@ts-ignore
        this.totalPatientCount = this.PatientCount
        this.totalcountPatent()
    }

    addBmp(event: any) {
        const value = event.target.value
        const multiInsert: dailyDoctors = new dailyDoctors();
        const name = this.PatientForm.get('amDoctorName')?.value
        multiInsert.amDoctorName = name
        multiInsert.amPatientCount = value
        this.multiIncidental.push(multiInsert)
    }


    addBulk() {
        this.spinner.show()
        this.multiIncidental = this.multiIncidental.filter(item =>
            item.amFormID !== '' &&
            item.amFormDate !== '' &&
            item.amDoctorName !== '' &&
            item.amPatientCount !== ''
        );
        if (this.multiIncidental.length > 0) {
            this.PatientForm.controls['opDoctors'].setValue(this.multiIncidental)
        }
        this.PatientForm.controls["amFormID"].setValue(this.DoctorID[0])
        this.PatientForm.controls["amFormDate"].setValue(this.currentDate)
        this.PatientForm.controls["activeYN"].setValue("Yes")
        this.PatientForm.controls["deleteYN"].setValue("No")
        this.appService
            .addBulkDetails(this.PatientForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                this.SuccessAlert()
            }, (error: any) => {
                this.spinner.hide()
                this.errorAlert()
            },
                () => console.log("HTTP request completed."))
    }

    getAllName() {
        this.appService
            .getAllDoctorNames()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                // @ts-ignore
                this.getAllDoctorName = data;
                var localdoctorNames: String[] = []
                for (let addDoctor of this.getAllDoctorName) {
                    localdoctorNames.push(addDoctor.doctorName)

                }
                this.doctorName = localdoctorNames
            })
    }

    getOPPatient() {
        this.appService.getAllPatientDeatils()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.getOPPatients = data
                var localPatient: PatientCount[] = []
                for (let ep of this.getOPPatients) {
                    if (this.currentDate == ep.amFormDate) {
                        localPatient.push(ep)
                    }
                }
            })
    }

    getDateFetch() {
        this.appService
            .getDateFetch()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                // @ts-ignore
                this.OpPaientDetails = data;
                for (let FormDate of this.OpPaientDetails) {
                    if (this.currentDate == FormDate.amFormDate) {
                        this.icon = false
                        this.multpleDoctors = false
                        this.getDailyDoctor()
                        this.PatientForm.controls["amDoctorName"].setValue(FormDate.amDoctorName);
                        this.PatientForm.controls["amPatientCount"].setValue(FormDate.amPatientCount);
                        this.PatientForm.controls["amTotalCases"].setValue(FormDate.amTotalCases);
                        this.PatientForm.controls["amTotalInPatient"].setValue(FormDate.amTotalInPatient);
                        this.PatientForm.controls["amSurgeryMinorPatient"].setValue(FormDate.amSurgeryMinorPatient);
                        this.PatientForm.controls["amSurgeryMajorPatient"].setValue(FormDate.amSurgeryMajorPatient);
                        this.PatientForm.controls["amGeneralRoom1"].setValue(FormDate.amGeneralRoom1);
                        this.PatientForm.controls["amGeneralRoom2"].setValue(FormDate.amGeneralRoom2);
                        this.PatientForm.controls["amGeneralRoom3"].setValue(FormDate.amGeneralRoom3);
                        this.PatientForm.controls["amGeneralRoom4"].setValue(FormDate.amGeneralRoom4);
                        this.PatientForm.controls["amGeneralRoom5"].setValue(FormDate.amGeneralRoom5);
                        this.PatientForm.controls["amGeneralRoom6"].setValue(FormDate.amGeneralRoom6);
                        this.PatientForm.controls["amRoom101"].setValue(FormDate.amRoom101);
                        this.PatientForm.controls["amRoom102"].setValue(FormDate.amRoom102);
                        this.PatientForm.controls["amRoom104"].setValue(FormDate.amRoom104);
                        this.PatientForm.controls["amComments"].setValue(FormDate.amComments)
                        this.PatientForm.controls["activeYN"].setValue(FormDate.activeYN);
                        this.PatientForm.controls["deleteYN"].setValue(FormDate.deleteYN);
                        this.PatientForm.controls["createdDate"].setValue(FormDate.createdDate);
                        this.PatientForm.controls["createdBy"].setValue(FormDate.createdBy);
                    } else {
                        this.PatientForm.reset()
                        this.PatientForm.controls["amFormDate"].setValue(this.currentDate);
                    }
                }
            });
    }

    getDailyDoctor() {
        this.appService
            .getDailyDoctor(this.currentDate)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.DailyDate = data
                for (var DD of this.DailyDate) {
                    if (this.currentDate == DD.amFormDate) {
                        this.doctorDetails = true
                        this.icon = false
                        this.multiIncidental = this.DailyDate
                        this.PatientForm.controls["multiInput"].setValue(DD.amDoctorName)
                        this.PatientForm.controls["multi"].setValue(DD.amPatientCount)

                    } else {
                        this.doctorDetails = false
                    }
                }

            })
    }

    getPatientCount() {
        this.totalcount = this.PatientForm.get("amPatientCount")?.value
        this.PatientForm.controls["amTotalCases"].setValue(this.totalcount);
    }

    totalcountPatent() {
        this.total = Number(this.totalPatientCount) + Number(this.PatientForm.get("amTotalCases")?.value)
        this.PatientForm.controls["amTotalCases"].setValue(this.total)
    }

    totalamGeneralRoom1() {
        if (this.PatientForm.get("amGeneralRoom1")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }
    totalamGeneralRoom2() {
        if (this.PatientForm.get("amGeneralRoom2")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }

    }

    totalamGeneralRoom3() {
        if (this.PatientForm.get("amGeneralRoom3")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamGeneralRoom4() {
        if (this.PatientForm.get("amGeneralRoom4")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamGeneralRoom5() {
        if (this.PatientForm.get("amGeneralRoom5")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamGeneralRoom6() {
        if (this.PatientForm.get("amGeneralRoom6")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamRoom101() {
        if (this.PatientForm.get("amRoom101")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamRoom102() {
        if (this.PatientForm.get("amRoom102")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }

    totalamRoom104() {
        if (this.PatientForm.get("amRoom104")?.value == true) {
            this.cureentPatient = 1
            this.totalInpatientCount()
        } else {
            this.cureentPatient = 1
            this.totaldecreaseInpatientCount()
        }
    }


    totalInpatientCount() {
        this.totalInPatientCount = Number(this.cureentPatient) + Number(this.PatientForm.get('amTotalInPatient')?.value)
        this.PatientForm.controls["amTotalInPatient"].setValue(this.totalInPatientCount)
    }
    totaldecreaseInpatientCount() {
        this.totalInPatientCount = Number(this.PatientForm.get('amTotalInPatient')?.value) - Number(this.cureentPatient)
        this.PatientForm.controls["amTotalInPatient"].setValue(this.totalInPatientCount)
    }
    SuccessAlert() {
        Swal.fire({
            title: "Details saved Sucessfully",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            showClass: {
                popup: "animate_animated animate_fadeInDown"
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload()
            }
        });
    }

    errorAlert() {
        Swal.fire({
            title: "Sorry, Please Try Again Your Registration Process",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate_animated animate_fadeInDown"
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
            }
        });
    }
}
