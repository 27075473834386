<div class="container-fluid">
  <div class="row mt-5"></div>
  <div class="row mt-5"></div>
  <div class="row mt-5"></div>
  <div class="row mt-5"></div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <p class="text-end">{{formatDate(getPrintData!.training_end_date)}}</p>
      <div class="row mt-4">
        <h4 class="text-center"><strong style="border-bottom: black solid 1px;">TO WHOMSOEVER IT MAY CONCERN </strong>
        </h4>
      </div>
      <div class="row mt-4">
        <p>This is to certify <strong>{{getMr}}. J. Remin Jose</strong> that was in our Training
          from
          <strong style="white-space: nowrap;">{{formatDate(getPrintData!.training_start_date)}}</strong> to
          <strong style="white-space: nowrap;">{{formatDate(getPrintData!.training_end_date)}}</strong>
          and designated as <strong style="white-space: nowrap;">{{getPrintData!.designation}}</strong>
        </p>
      </div>
      <div class="row mt-2"></div>
      <div class="row">
        <div class="row mt-2">
          <p>During this time, we found {{getHim}} honest, dedicated, hardworking and <br>
            well-behaved during {{getHim}} training period with us.</p>
        </div>
      </div>
      <div class="row mt-2"></div>
      <div class="row mt-2">
        <p>
          We wish <strong>{{getMr}}. {{getPrintData!.employee_name}}</strong> all the very best in
          {{getHim}} future endeavors.
        </p>
      </div>
      <div class="row mt-4"></div>
      <div class="row mt-4"></div>
      <div class="row mt-2">
        <p>
          For <strong> Varshini Hospital </strong>
        </p>
      </div>
      <div class="row mt-5"></div>
      <div class="row mt-2">
        <p>
          <strong> Dr.B.Murugaprakash M.D., D.Diab <br></strong>
          Regn No : 80934<br>
          Physician & Diabetologist
        </p>
      </div>
    </div>

  </div>
  <div class="col-md-1"></div>
</div>