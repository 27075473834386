import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { takeUntil, Subject } from "rxjs";
import { addVendor } from '@/model/addVendor';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-addVendor',
  templateUrl: './addVendor.component.html',
  styleUrls: ['./addVendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  vendorDetails: addVendor[] = [];
  addVendor: addVendor | undefined;

  constructor(private appservice: AppService, private toastr: ToastrService, private spinner: NgxSpinnerService) {
  }
  newvendor = new UntypedFormGroup(
    {
      vendorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      mobileNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      city: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      gstNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankAccountNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      accHolderName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      branch: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bankIfscCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    }
  );
  public NewVendorErrors = {
    vendorName: "",
    mobileNo: "",
    city: "",
    gstNo: "",
    bankName: "",
    bankAccountNo: "",
    accHolderName: "",
    branch: "",
    bankIfscCode: "",
  };
  ngOnInit() {

  }
  onSubmit() {
    this.formvalidation();
  }
  formvalidation() {
    this.NewVendorErrors.vendorName = "";
    this.NewVendorErrors.mobileNo = "";
    this.NewVendorErrors.city = "";
    this.NewVendorErrors.gstNo = "";
    this.NewVendorErrors.bankName = "";
    this.NewVendorErrors.bankAccountNo = "";
    this.NewVendorErrors.accHolderName = "";
    this.NewVendorErrors.branch = "";
    this.NewVendorErrors.bankIfscCode = "";
    let hasError = false;

    if (this.newvendor.get("vendorName")?.invalid) {
      this.NewVendorErrors.vendorName = "*Vendor Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("mobileNo")?.invalid) {
      if (this.newvendor.get("mobileNo")?.errors?.["required"]) {
        this.NewVendorErrors.mobileNo = "*Mobile Number is required";
      }
      if (this.newvendor.get("mobileNo")?.getError("pattern")) {
        this.NewVendorErrors.mobileNo = "*Enter a 10-digit Mobile number.";
      }
      hasError = true;
    }
    if (this.newvendor.get("city")?.invalid) {
      this.NewVendorErrors.city = "*City is Required";
      hasError = true;
    }
    if (this.newvendor.get("gstNo")?.invalid) {
      this.NewVendorErrors.gstNo = "*GST is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankName")?.invalid) {
      this.NewVendorErrors.bankName = "*Bank Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankAccountNo")?.invalid) {
      this.NewVendorErrors.bankAccountNo = "*Account Number is Required";
      hasError = true;
    }
    if (this.newvendor.get("accHolderName")?.invalid) {
      this.NewVendorErrors.accHolderName = "*Account Holder Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("branch")?.invalid) {
      this.NewVendorErrors.branch = "*Branch Name is Required";
      hasError = true;
    }
    if (this.newvendor.get("bankIfscCode")?.invalid) {
      this.NewVendorErrors.bankIfscCode = "*IFSC Code is Required";
      hasError = true;
    }
    if (!hasError) {
      this.saveVendor();
    }

  }

  saveVendor() {
    this.spinner.show();
    this.appservice
      .saveVendor(this.newvendor.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // @ts-ignore
        this.addVendor = data;
        this.spinner.hide();
        this.toastr.success("Vendor Details Saved Successfully")
        this.newvendor.reset()
      }, (err: HttpErrorResponse) => {
        this.spinner.hide();
        var localerror: string = ""
        localerror = err.error.message || 'An error occurred'
        if (localerror == "already exists") {
          this.toastr.error(err.error.message || 'An error occurred', 'Error');
        } else {
          console.log("Something Went Wrong")
          this.toastr.error("Something went wrong , Please try again later....")
        }
      })
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  focusNext(event: KeyboardEvent, nextElementId: string) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextElement = document.getElementById(nextElementId);
      if (nextElement) {
        nextElement.focus();
      }
    }
  }


}