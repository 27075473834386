<nav class="navbar navbar-expand-lg navbar-light sticky-top" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">New Joining Form</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
      <form class="d-flex">
        <img (click)="reload()" src="assets/image/baseline_autorenew_black_24dp.png" style="height: 33px; width: 33px;">
      </form>
    </div>
  </div>
</nav>

<div class="container-fluid c">
  <div class="container">
    <form [formGroup]="FileUpload">
      <div class="row col-12 mx-2">
        <div class="row col-md-4 mt-2">
          <label>New Joining Id : {{formId}}</label>
        </div>
        <div class="row col-md-4 mt-2">
          <label>Employee Name : {{employeeName}}</label>
        </div>
      </div>
      <div class="alert alert-light mt-3" role="alert">
        Note:<span style="color:red;">*</span> Upload image file and pdf file only like png, jpg, pdf,...
      </div>
      <div class="row mt-3">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Aadhar Card <span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="aadhar_card" (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.aadhar_card" class="text-danger">{{fileUploadErrors.aadhar_card}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="aadharCardfun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>

      </div>

      <div class="row mt-3" *ngIf="passBook">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Bank Passbook<span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="bank_passbook" (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.bank_passbook" class="text-danger">{{fileUploadErrors.bank_passbook}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="bankpassbookFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Employee Photo<span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="employee_photo" (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.employee_photo" class="text-danger">{{fileUploadErrors.employee_photo}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="employeePhotoFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Course Completion Certificates<span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="course_completion_certificates"
                (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.course_completion_certificates"
              class="text-danger">{{fileUploadErrors.course_completion_certificates}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="courseCompletionFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>


      <div class="row mt-3" *ngIf="experienceDisable">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Experience Certificate </label>
              <input class="form-control" type="file" formControlName="experience_certificate"
                (change)="onFileChange($event)">
            </div>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="experienceFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>


      <div class="row mt-3">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">Pan Card </label>
              <input class="form-control" type="file" formControlName="pan_card" (change)="onFileChange($event)">
            </div>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="PanCardFun()">Upload</button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="collegeId">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">College Id Card<span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="college_id_card" (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.college_id_card"
              class="text-danger">{{fileUploadErrors.college_id_card}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="CollegeCardFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="collegeletter">
        <div class="col-md-2"></div>
        <div class="card col-md-5">
          <div class="row">
            <div class="col-md-8">
              <label class="form-label">College Training Letter<span style="color:red;">*</span></label>
              <input class="form-control" type="file" formControlName="collegeLetter" (change)="onFileChange($event)">
            </div>
            <span *ngIf="fileUploadErrors.collegeLetter" class="text-danger">{{fileUploadErrors.collegeLetter}}</span>
            <div class="col-md-2 mt-3">
              <button type="button" class="btn btn-primary" *ngIf="uploadeButton" (click)="CollegeLetterFun()">Upload
              </button>
            </div>
          </div>
          <div class="row mt-2"></div>
        </div>
      </div>


      <div class="text-center mt-3">
        <button type="button" class="btn btn-success ml-5" *ngIf="nextButton" (click)="Navigate()"><span
            style="margin:center;">Return To Home</span></button>
      </div>
      <div class="row mt-4"></div>
      <div class="row mt-4"></div>
    </form>
  </div>
</div>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
<app-footer></app-footer>