import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-amdds-dashboard',
  templateUrl: './amdds-dashboard.component.html',
  styleUrls: ['./amdds-dashboard.component.scss']
})
export class AmddsDashboardComponent implements OnInit {
  userDisable = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('userType') == 'USER') {
      this.userDisable = false;
    } else {
      this.userDisable = true;
    }
  }

  navgation() {
    if (localStorage.getItem('userType') == 'USER') {
      this.router.navigate(['/OverAll-VendorPayment'])
    } else {
      this.router.navigate(['/AddVendor'])
    }
  }

  reload() {
    window.location.reload();
  }

  logout() {
    location.replace("http://136.233.82.215:8080/AMDataDailySheet/HTML/Login/Login.html")
  }

  home() {
    window.location.reload()
  }
}
