<app-amdds-header></app-amdds-header>
<div class="container-fluid">
  <div class="row mb-5"></div>
  <div class="row mb-3"></div>
  <h2 class="bold text-center">Anitha Medical Stock View Screen</h2>

  <div class="container mt-3">
    <h5 class="text-danger">Note * : <span>To view the complete stock details, click on the medicine name.</span></h5>
    <div class="row">
      <div class="col-4 form-floating">
        <input type="date" class="form-control" placeholder="Date Time" (change)="date($event)" [max]="currentDate">
        <label class="ms-3 form-label">Date Filter</label>
      </div>
      <div class="col-3">
      </div>
      <div class="col-4 mb-2 mt-3 ms-2">
        <input type="search" class="rounded-pill form-control" placeholder="Search" [(ngModel)]="searchText">
      </div>
    </div>
    <div class="container">
      <div class="table-responsive">
        <table class="table">
          <thead class="rounded">
            <tr class="sticky-top">
              <th>S.No</th>
              <th>Description</th>
              <th>Total Stock</th>
              <th>Unit</th>
              <th>Vendor Name</th>
            </tr>
          </thead>
          <tbody *ngFor="let stock of dailyStockDetails| filter: searchText; let i = index">
            <tr (click)="desc(stock)">
              <td>{{i + 1}}</td>
              <td>{{stock.description}}</td>
              <td>{{stock.totalStock}}</td>
              <td>{{stock.unit}}</td>
              <td>{{stock.vendorName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal" id="myModal" [style.display]="isViewShow ? 'block' : 'none'">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> <b>Medicine Name Based Stock Details</b> </h5>
          <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive p-0 text-center" style="height: 350px;overflow-y: scroll;">

            <table class="table">
              <thead>
                <tr class="sticky-top">
                  <th>S.No</th>
                  <th>Description</th>
                  <th>Total Stock</th>
                  <th>Bacth NUmber</th>
                  <th>Expiry Date</th>
                  <th>Stock Upload Date</th>
                </tr>
              </thead>
              <tbody *ngFor="let dec of allStcokDetails; let i = index">
                <tr>
                  <td>{{i+1}}</td>
                  <td>{{dec.description}}</td>
                  <td>{{dec.totalStock}}</td>
                  <td>{{dec.batchNumber}}</td>
                  <td>{{dec.expiryDate}}</td>
                  <td>{{dec.createdAt | date:'dd-MM-yyyy' }}</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner>
    <div class="loader">
      <div class="inner one"></div>
      <div class="inner two"></div>
      <div class="inner three"></div>
    </div>
  </ngx-spinner>
  
  <app-amdds-footer></app-amdds-footer>