<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Daily Stock Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/StockUpload"><b>Stock Upload</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/SameStockDetails"><b>Unsold Stock Details</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <div class="row py-5"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mt-3">
        <label class="ml-2 form-label">Date Filter</label>
        <input type="date" class="form-control" placeholder="Date Time" (change)="date($event)" [min]="currentDate"
          [max]="currentDate">
      </div>
      <div class="col-lg-5 mt-3">
        <div class="row">
          <div class="col-md-8">
            <label class="form-label ml-2">Daily Stock Upload<span style="color:red;">*</span></label>
            <input class="form-control" type="file" [disabled]="isFileUploadReadonly" id="fileUpload"
              (change)="handleFileInput1($event)">
          </div>
          <div class="col-lg-3 mt-4">
            <button *ngIf="uploadButton" type="button" class="btn btn-primary mt-2" (click)="uploadData()">Upload
            </button>
          </div>
        </div>
        <div class="row mt-4"></div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="container">
    <div class="table-responsive">
      <table class="table">
        <thead class="rounded">
          <tr class="sticky-top">
            <th>No</th>
            <th>Description</th>
            <th>Total Stock</th>
            <th>Unit</th>
            <th>MRP Rate</th>
            <th>Total Amount</th>
            <th>Bacth Number</th>
            <th>Expiry Date</th>
            <th>Vendor Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of dailyStockDetails; let i = index">
            <td>{{i + 1}}</td>
            <td>{{data.description}}</td>
            <td>{{data.totalStock}}</td>
            <td>{{data.unit}}</td>
            <td>{{data.mrpRate}}</td>
            <td>{{data.totalAmount.toFixed(2)}}</td>
            <td>{{data.batchNumber}}</td>
            <td>{{data.expiryDate}}</td>
            <td>{{data.vendorName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>