import { Component, HostBinding, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { NewJoinForms } from "@/model/new_JoinForms";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { printDataService } from "@/model/printDataService";
import { Router } from "@angular/router";


@Component({
  selector: "app-training-certificate",
  templateUrl: "./training-certificate.component.html",
  styleUrls: ["./training-certificate.component.scss"]
})
export class TrainingcertificateComponent implements OnInit {
  searchText: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  datePipe: DatePipe = new DatePipe("en-IN");
  getPrintData: NewJoinForms | undefined;
  getAllEmployees: NewJoinForms[] = [];
  localdata: NewJoinForms[] = [];
  getMr: string | undefined;
  getHim: string | undefined;
  isVisible = false;


  constructor(private appService: AppService,
    private spinner: NgxSpinnerService, private dataService: printDataService,
    private router: Router) {
  }

  ngOnInit() {
    if (this.dataService.printData != undefined) {
      this.getPrintData = this.dataService.printData;
      if (this.getPrintData.gender == "Female") {
        this.getMr = "Ms";
        this.getHim = "her";
      } else {
        this.getMr = "Mr";
        this.getHim = "him";
      }
      //@ts-ignore
      this.localdata = this.getPrintData;
    }
    if (!this.isVisible) {
      setTimeout(function () {
        window.print();
      }, 3000);
      setTimeout(() => {
        this.router.navigate(["ReportScreen"]);
      }, 8000);
    }
  }

  formatDate(date: string) {
    const formattedDate = this.datePipe.transform(date, "dd - MM - yyyy");
    return formattedDate;
  }


}

