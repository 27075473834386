<app-amdds-header></app-amdds-header>
<app-vendor-navbar></app-vendor-navbar>
<div class="container-fluid">
    <div class="row py-5"></div>

    <section>
        <div class="row mt-3">
            <div class="row ml-1 mr-1">
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select1" aria-label="Floating label select"
                            (change)="updateStock($event, 'approval')">
                            <option selected>Select Payment Status</option>
                            <option *ngFor="let approval of this.adminApproval">{{approval}}</option>
                        </select>
                        <label for="Select1">Payment Status</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select2" aria-label="Floating label select"
                            (change)="updateStock($event, 'vendor')">
                            <option selected>Select vendor Name</option>
                            <option *ngFor="let name of this.vendorName">{{name}}</option>
                        </select>
                        <label for="Select2">Vendor Name</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating">
                        <select class="form-select" id="Select5" aria-label="Floating label select"
                            (change)="updateStock($event, 'date')">
                            <option selected>Select Paid Date</option>
                            <option *ngFor="let date of this.uniqueDates">{{date}}</option>
                        </select>
                        <label for="Select5">Payment Date</label>
                    </div>
                </div>
                <div class="col-md-2 mt-2"></div>
                <div class="col-md-2 mt-2"></div>
                <div class="col-md-2 mt-2">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
                        <label>Search</label>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="row ml-2 mr-2">
        <div class="col-md-12 mt-2">
            <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
                <table class="table table-sm table-bordered text-center table-hover mb-0">
                    <thead class="header sticky-top">
                        <tr>
                            <th>SL.NO</th>
                            <th>Invoice Date</th>
                            <th class="invoce">Invoice No</th>
                            <th>Aging</th>
                            <th>Vendor Name</th>
                            <th>Invoice Amount</th>
                            <th class="invoce">Transaction ID</th>
                            <th>Paid Date</th>
                            <th>PaymentStatus</th>
                            <th class="command">Comments</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="this.stock.length > 0; else noDataFound">
                        <tr class="widthset" *ngFor="let data of this.stock|filter: searchText; let i=index">
                            <td>{{ i+1 }}</td>
                            <td>{{ data.date }}</td>
                            <td class="invoce">{{ data.invoiceNo }}</td>
                            <td>{{ data.aging }}</td>
                            <td>{{ data.vendorName }}</td>
                            <td>{{ data.invoiceAmount | currency:'INR':'' }}</td>
                            <td class="invoce">
                                <p class="text-center" *ngIf="data.transactionId !== ''">{{ data.transactionId }}</p>
                            </td>
                            <td>
                                <p class="text-center" *ngIf="data.payDate !== '' ">{{ data.payDate }}</p>
                            </td>
                            <td>{{ data.paymentStatus}}</td>
                            <td class="command">{{data.status}}</td>
                        </tr>
                    </tbody>
                    <ng-template #noDataFound>
                        <tr>
                            <td colspan="11">Data Not Found</td>
                        </tr>
                    </ng-template>
                </table>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>