import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-amdds-footer',
  templateUrl: './amdds-footer.component.html',
  styleUrls: ['./amdds-footer.component.scss']
})
export class AmddsFooterComponent implements OnInit {
  public currentYear: string = DateTime.now().toFormat('y');
  constructor() { }

  ngOnInit(): void {
  }

}
