<nav class="navbar navbar-expand fixed-top" style="background-color: #7895c0;">
  <div class="container-fluid">
    <img src="../../assets/img/varshinihospital.png" class="img-fluid" alt="" width="33" height="33">
    <strong class="ms-2"> Varshini Hospital </strong>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
      <form class="d-flex" *ngIf="isShow">
        <i class="ion ion-home" style="font-size:23px;color: #000;" (click)="home()"></i>
        <img class="ms-2" (click)="reload()" src="../../assets/img/baseline_autorenew_black_24dp.png"
          style="height: 23px; width: 23px;">
        <img class="ms-2" src="../../assets/img/logout_icon_151219.png" style="height: 23px; width: 23px;"
          (click)="logout()">
        <img>
        <button class="btn btn-outline-secondary toggle-theme-btn ms-2" (click)="toggleTheme()">
          <i class="fas" [ngClass]="isDarkMode ? 'fa-moon' : 'fa-sun'"></i>
        </button>
      </form>
    </div>
  </div>
</nav>