import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { AddStockDetails } from "@/model/addStockDetails";

@Component({
  selector: "app-stockupload",
  templateUrl: "./stockupload.component.html",
  styleUrls: ["./stockupload.component.scss"]
})
export class StockUploadComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileToUpload: File | null = null;
  uploadedData: any[] = [];
  dailyStockDetails: AddStockDetails[] = [];
  uploadButton = false;
  currentDate: String;
  getDate: string = "";
  isFileUploadReadonly = true;

  constructor(private apiService: AppService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private datePipe: DatePipe) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }

  stockForm = new UntypedFormGroup({
    stockId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    unit: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    totalStock: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    description: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  ngOnInit(): void {

  }

  date(event: any) {
    const inputValue = event.target.value;
    const values = this.datePipe.transform(inputValue, "yyyy-MM-dd") ?? "";
    this.getDate = values;
    this.getDailyStock();
  }

  getDailyStock() {
    this.apiService.getDailyStockDetails(this.getDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(date => {
        var localdate: AddStockDetails[] = [];
        localdate = date;
        var localdate: AddStockDetails[] = date;
        var recordExists = localdate.some(item => item.createdAt === this.getDate);
        if (recordExists) {
          this.toastr.error("The record has been already saved.");
          this.isFileUploadReadonly = true;
        } else {
          this.isFileUploadReadonly = false;
        }
      }, (err: any) => {
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }


  handleFileInput1(event: any) {
    const currentHour = new Date().getHours();
    // if(localStorage.getItem('userType') == 'USER'){
    if (currentHour < 11 && localStorage.getItem('userType') == 'USER' || localStorage.getItem('userType') == 'ADMIN') {
      const file = event.target.files[0];

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const data: string = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        const sheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];

        const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        let stockIdCounter = 1; // Initialize a counter for stockId

        this.dailyStockDetails = excelData.map((row: any) => {
          const stockDetails = {
            stockId: stockIdCounter++,
            description: row['2'],
            totalStock: row['4'],
            unit: row['3'],
            mrpRate: row['5'],
            totalAmount: row['6'],
            batchNumber: row['7'],
            expiryDate: row['8'],
            vendorName: row['9'],
            createdAt: this.getDate,
            createdBy: 'admin'
          };

          return stockDetails;
        });
        this.uploadButton = true;
      };
      reader.readAsBinaryString(file);
    } else {
      this.toastr.error("Time's Up, Please contact Admin");
      const inputFile = document.getElementById("fileUpload") as HTMLInputElement;
      inputFile.value = "";
    }

  }


  uploadData() {
    this.spinner.show();
    this.stockForm.controls["createdAt"].setValue(this.getDate);
    this.apiService.addStockDetails(this.dailyStockDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        this.dailyStockDetails = []
        this.toastr.success("Your File Upload Successfully Completed");
      }, (err: any) => {
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
        this.spinner.hide();
      });
  }
}
