import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';


@Component({
  selector: 'app-vendor-navbar',
  templateUrl: './vendor-navbar.component.html',
  styleUrls: ['./vendor-navbar.component.scss']
})
export class VendorNavbarComponent implements OnInit {
  userDisable: boolean = false;

  constructor(private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    if (localStorage.getItem('userType') == 'USER' || localStorage.getItem('userType') !== "ADMIN") {
      this.userDisable = false;
    } else {
      this.userDisable = true;
    }
  }

}
