import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileList: File[] = [];
  listOfFiles: any[] = [];
  fileUploadStatus: string = "Exist";
  formId: string = ""
  employeeName: string = ""
  formData = new FormData();
  uploadeButton = false
  nextButton = false
  collegeId = false
  passBook = true
  collegeletter = false
  experienceDisable = true
  constructor(private appService: AppService, private toastr: ToastrService, private router: Router,
    private spinner: NgxSpinnerService) { }

  FileUpload = new FormGroup(
    {
      employee_type: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      newjoin_id: new FormControl('', Validators.required),
      employee_joining_date: new FormControl('', Validators.required),
      employee_name: new FormControl('', Validators.required),
      employee_father_name: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      date_of_birth: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      designation: new FormControl('', Validators.required),
      qualification: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      employeelocation: new FormControl('', Validators.required),
      blood_group: new FormControl('', Validators.required),
      aadhar_no: new FormControl('', Validators.required),
      bank_name: new FormControl('', Validators.required),
      branch_name: new FormControl('', Validators.required),
      bank_ifsc_code: new FormControl('', Validators.required),
      name_as_in_Passbook: new FormControl('', Validators.required),
      account_no: new FormControl('', Validators.required),
      employee_contact_no: new FormControl('', Validators.required),
      emergency_contact_no: new FormControl('', Validators.required),
      salary_value_fixed: new FormControl('', Validators.required),
      trainer_college_name: new FormControl('', Validators.required),
      trainer_college_roll_no: new FormControl('', Validators.required),
      referencer_name: new FormControl('', Validators.required),
      training_start_data: new FormControl('', Validators.required),
      training_end_data: new FormControl('', Validators.required),
      aadhar_card: new FormControl('', Validators.required),
      bank_passbook: new FormControl('', Validators.required),
      employee_photo: new FormControl('', Validators.required),
      course_completion_certificates: new FormControl('', Validators.required),
      experience_certificate: new FormControl('', Validators.required),
      pan_card: new FormControl('', Validators.required),
      college_id_card: new FormControl('', Validators.required),
      collegeLetter: new FormControl('', Validators.required)
    }
  )

  ngOnInit() {
    //@ts-ignore
    this.formId = localStorage.getItem('newJoiningId')
    //@ts-ignore
    this.employeeName = localStorage.getItem('newEmploeeName')

    if (localStorage.getItem('employeeType') !== 'Full Time' && localStorage.getItem('employeeType') !== 'Part Time') {
      this.collegeId = true;
      this.experienceDisable = false;
      this.collegeletter = true
    }
    if (localStorage.getItem('stipend') == 'Yes' || localStorage.getItem('stipend') == null) {
      this.passBook = true
    } else {
      this.passBook = false
    }
  }
  public fileUploadErrors = {
    aadhar_card: '',
    bank_passbook: '',
    employee_photo: '',
    course_completion_certificates: '',
    experience_certificate: '',
    pan_card: '',
    college_id_card: '',
    collegeLetter: '',
  }

  onFileChange(event: any) {
    this.fileList = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
      if (fileSizeInMb <= 1) {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.FileUpload.patchValue({});
          };
          reader.readAsDataURL(event.target.files[0]);
        }
        this.fileList.push(selectedFile);
        this.listOfFiles.push(selectedFile.name);
        this.fileUploadStatus = "Uploaded";
        this.uploadeButton = true
      } else {
        this.fileUploadError()
      }
    }
  }

  aadharCardfun() {
    this.fileUploadErrors.aadhar_card = "";
    let hasError = false;

    if (this.FileUpload.get('aadhar_card')?.invalid) {
      this.fileUploadErrors.aadhar_card = "*Aadher File is Required"
      hasError = true
    }
    if (!hasError) {
      this.fileUpload(this.formId, "aadharCard")
    }

  }

  bankpassbookFun() {
    this.fileUploadErrors.bank_passbook = "";
    let hasError = false;

    if (localStorage.getItem('stipend') == 'Yes' && localStorage.getItem('employeeType') == 'Trainne' || localStorage.getItem('employeeType') !== 'Trainne')
      if (this.FileUpload.get('bank_passbook')?.invalid) {
        this.fileUploadErrors.bank_passbook = "*Bank Passbook File is Required"
        hasError = true
      }
    if (!hasError) {
      this.fileUpload(this.formId, "bankPassBook")
    }

  }

  employeePhotoFun() {
    this.fileUploadErrors.employee_photo = "";
    let hasError = false;

    if (this.FileUpload.get('employee_photo')?.invalid) {
      this.fileUploadErrors.employee_photo = "*Employee Photo File is Required"
      hasError = true
    }
    if (!hasError) {
      this.fileUpload(this.formId, "photo")
    }
  }

  courseCompletionFun() {
    this.fileUploadErrors.course_completion_certificates = "";
    let hasError = false;

    if (localStorage.getItem('employeeType') !== 'Trainne') {
      if (this.FileUpload.get('course_completion_certificates')?.invalid) {
        this.fileUploadErrors.course_completion_certificates = "*Course Completion Certificates File is Required"
        hasError = true
      }
    }
    if (!hasError) {
      this.fileUpload(this.formId, "educationCertificate")
      if (localStorage.getItem('employeeType') !== 'Trainne') {
        this.nextButton = true
      } else {
        this.nextButton = false
      }
    }
  }
  experienceFun() {
    this.fileUpload(this.formId, "experienceCertificate")
  }

  PanCardFun() {
    this.fileUpload(this.formId, "panCard")
  }

  CollegeCardFun() {
    this.fileUploadErrors.college_id_card = ""
    let hasError = false;
    if (localStorage.getItem('employeeType') == 'Trainne')
      if (this.FileUpload.get('college_id_card')?.invalid) {
        this.fileUploadErrors.college_id_card = "*College Id Card File is Required"
        hasError = true
      }
    if (!hasError) {
      this.fileUpload(this.formId, "collegeIdCard")
    }
  }


  CollegeLetterFun() {
    this.fileUploadErrors.collegeLetter = ""
    let hasError = false;
    if (localStorage.getItem('employeeType') == 'Trainne')
      if (this.FileUpload.get('collegeLetter')?.invalid) {
        this.fileUploadErrors.collegeLetter = "*College Training Letter File is Required"
        hasError = true
      }
    if (!hasError) {
      this.fileUpload(this.formId, "collegeLetter")
      this.nextButton = true
    }
  }



  fileUpload(id: string, fileName: string) {
    this.spinner.show()
    const formData = new FormData();
    for (const file of this.fileList) {
      formData.append('file', file, file.name);
    }
    this.appService.fileUpload(formData, id, fileName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        this.toastr.success("Your File Upload Successfully Completed")
        if (fileName == 'aadharCard') {
          this.FileUpload.get('aadhar_card')?.disable()
        }
        else if (fileName == 'bankPassBook') {
          this.FileUpload.get('bank_passbook')?.disable()
        }
        else if (fileName == 'photo') {
          this.FileUpload.get('employee_photo')?.disable()
        }
        else if (fileName == 'educationCertificate') {
          this.FileUpload.get('course_completion_certificates')?.disable()
        }
        else if (fileName == 'experienceCertificate') {
          this.FileUpload.get('experience_certificate')?.disable()
        }
        else if (fileName == 'panCard') {
          this.FileUpload.get('pan_card')?.disable()
        }
        else if (fileName == 'collegeIdCard') {
          this.FileUpload.get('college_id_card')?.disable()
        }
        else if (fileName == 'collegeLetter') {
          this.FileUpload.get('college_letter')?.disable()
        }
        this.FileUpload.reset();
      }, (error: any) => {
        this.spinner.show()
        this.errorAlert()
        console.log("something went wrong")
      })
  }

  Navigate() {
    this.router.navigate([''])
    localStorage.removeItem('newJoiningId')
    localStorage.removeItem('newEmploeeName')
    localStorage.removeItem('employeeType')
    localStorage.removeItem('stipend')
  }

  reload() {
    window.location.reload();
  }

  fileUploadError() {
    Swal.fire({
      title: 'File size is large. Please upload below 1MP',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.FileUpload.reset();
      }
    });
  }

}
