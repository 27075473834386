<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light" style="background-color:darkgray" *ngIf="disablenav">
  <div class="container-fluid">
    <a class="navbar-brand">New Joining Form</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/NewJoinForm"><b>Form Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/ReportScreen"><b>View Form</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid c mt-5">
  <div class="py-2"></div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-3">
      <div class="form-floating mb-3 mt-5">
        <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
        <label>Search</label>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row mt-3">
    <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">

      <table class="table table-hover mb-0">
        <thead class="sticky-top border-0" style="color: #d55f07; background: white">
          <tr>
            <th class="text-center">S.No</th>
            <th class="text-center">Employee ID</th>
            <th class="text-center">Employee Name</th>
            <th class="text-center">Employee Location</th>
            <th class="text-center">Employee Type</th>
            <th class="text-center">View</th>
            <th class="text-center">Training Certificate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let forms of getAllEmployees | filter: searchText; let i = index;">
            <td class="text-center">{{i + 1}}</td>
            <td class="text-center">{{forms.newjoin_id}}</td>
            <td class="text-center">{{forms.employee_name}}</td>
            <td class="text-center">{{forms.location}}</td>
            <td class="text-center">{{forms.employee_type}}</td>
            <td class="text-center">
              <h4 data-toggle="tooltip" data-placement="top" title="Click to View the Report">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-eye-fill" viewBox="0 0 16 16" (click)="openModel(forms.newjoin_id)">
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path
                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </h4>
            </td>

            <td class="text-center" *ngIf="forms.employee_type == 'Trainee'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-text"
                viewBox="0 0 16 16" (click)="selectUlr(forms.newjoin_id)">
                <path
                  d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                <path
                  d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

<div class="modal bg-light bg-opacity-50" id="exampleModalCenter" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [style.display]="isMapShow ? 'block' : 'none'">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Document</h5>
        <button data-toggle="tooltip" data-placement="top" title="Close" type="button" class="close text-danger"
          data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="imagesUrl">
      </div>
    </div>
  </div>
</div>

<div class="modal bg-light bg-opacity-50" id="exampleModalCenter1" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [style.display]="isViewShow ? 'block' : 'none'">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Employee Details</h5>
        <button data-toggle="tooltip" data-placement="top" title="Close" type="button" class="close text-danger"
          data-dismiss="modal" aria-label="Close" (click)="close1()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body tablemodel">
        <div class="table-responsive" *ngFor="let getdata of this.employeeData">
          <table class="table table-bordered table-hover">
            <tbody>
              <tr>
                <th>Employee Id</th>
                <td>{{getdata.newjoin_id}}</td>
              </tr>
              <tr>
                <th>Employee Type</th>
                <td>{{getdata.employee_type}}</td>
              </tr>
              <tr>
                <th>Employee Working Location</th>
                <td>{{getdata.location}}</td>
              </tr>
              <tr>
                <th>Employee Joining Date</th>
                <td>{{getdata.employee_joining_date}}</td>
              </tr>
              <tr>
                <th>Employee Name</th>
                <td>{{getdata.employee_name}}</td>
              </tr>
              <tr>
                <th>Employee Father Name</th>
                <td>{{getdata.employee_father_name}}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>{{getdata.gender}}</td>
              </tr>
              <tr>
                <th>Date Of Birth</th>
                <td>{{getdata.date_of_birth}}</td>
              </tr>
              <tr>
                <th>Employee Age</th>
                <td>{{getdata.age}}</td>
              </tr>
              <tr>
                <th>Designation</th>
                <td>{{getdata.designation}}</td>
              </tr>
              <tr>
                <th>Qualification</th>
                <td>{{getdata.qualification}}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{{getdata.address}}</td>
              </tr>
              <tr>
                <th>Employee Location</th>
                <td>{{getdata.employeelocation}}</td>
              </tr>
              <tr>
                <th>Blood Group</th>
                <td>{{getdata.blood_group}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="bankDetails">
              <tr>
                <th>Aadhar No</th>
                <td>{{getdata.aadhar_no}}</td>
              </tr>
              <tr>
                <th>Bank Name</th>
                <td>{{getdata.bank_name}}</td>
              </tr>
              <tr>
                <th>Branch Name</th>
                <td>{{getdata.branch_name}}</td>
              </tr>
              <tr>
                <th>Bank IFSC Code</th>
                <td>{{getdata.bank_ifsc_code}}</td>
              </tr>
              <tr>
                <th>Bank Holder Name</th>
                <td>{{getdata.name_as_in_Passbook}}</td>
              </tr>
              <tr>
                <th>Account Number</th>
                <td>{{getdata.account_no}}</td>
              </tr>
              <tr>
                <th>Employee Contact Number</th>
                <td>{{getdata.employee_contact_no}}</td>
              </tr>
              <tr>
                <th>Emergency Contact Number</th>
                <td>{{getdata.emergency_contact_no}}</td>
              </tr>
              <tr>
                <th>Employee Salary</th>
                <td>{{getdata.salary_value_fixed}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="trainerDisable">
              <tr>
                <th>College Name</th>
                <td>{{getdata.trainer_college_name}}</td>
              </tr>
              <tr>
                <th>College Roll Number</th>
                <td>{{getdata.trainer_college_roll_no}}</td>
              </tr>
              <tr>
                <th>Reference By</th>
                <td>{{getdata.referencer_name}}</td>
              </tr>
              <tr>
                <th>Training Start Date</th>
                <td>{{getdata.training_start_date}}</td>
              </tr>
              <tr>
                <th>Training End Date</th>
                <td>{{getdata.training_end_date}}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th>Documents</th>
                <td class="text-primary">
                  <p *ngIf="getdata.aadhar_card.includes('var')" (click)="photoView(getdata.newjoin_id, 'aadharCard')">
                    Aadhar
                    card</p>
                  <p *ngIf="getdata.bank_passbook.includes('var')"
                    (click)="photoView(getdata.newjoin_id, 'bankPassBook')">Bank
                    Passbook
                  </p>
                  <p *ngIf="getdata.employee_photo.includes('var')" (click)="photoView(getdata.newjoin_id, 'photo')">
                    Profile</p>
                  <p *ngIf="getdata.course_completion_certificates.includes('var')"
                    (click)="photoView(getdata.newjoin_id, 'educationCertificate')">Education Certificate</p>
                  <p *ngIf="getdata.experience_certificate.includes('var')"
                    (click)="photoView(getdata.newjoin_id, 'experienceCertificate')">Experience Certificate</p>
                  <p *ngIf="getdata.pan_card.includes('var')" (click)="photoView(getdata.newjoin_id, 'panCard')">Pan
                    card</p>
                  <p *ngIf="getdata.college_id_card.includes('var')"
                    (click)="photoView(getdata.newjoin_id, 'collegeIdCard')">
                    College Id
                    Card</p>
                  <p *ngIf="getdata.collegeLetter.includes('var')"
                    (click)="photoView(getdata.newjoin_id, 'collegeLetter')">
                    College Training Letter</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

<app-amdds-footer></app-amdds-footer>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>