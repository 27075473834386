import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AddStockDetails } from "@/model/addStockDetails";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-same-stock-list",
  templateUrl: "./same-stock-list.component.html",
  styleUrls: ["./same-stock-list.component.scss"]
})
export class SameStockListComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dailyStockDetails: AddStockDetails[] = [];
  daysDifference: { description: string, totalStock: string }[] = []
  currentDate: String;
  getDate: string = "";
  searchText: any;
  entrydata: string[] = [];
  UniqueData: string[] = [];


  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }


  ngOnInit(): void {
    this.getSameDailyStock()
  }

  getSameDailyStock() {
    this.spinner.show();
    this.apiService.getSameStockDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        const unchangedMedicines = this.findUnchangedMedicines(data);
        const uniqueProcedureIds = new Set(unchangedMedicines.map(item => item.description));
        const uniqueProcedureResults = Array.from(uniqueProcedureIds).map(procedureId => {
          return unchangedMedicines.find(item => item.description === procedureId);
        });
        this.dailyStockDetails = uniqueProcedureResults.sort((a, b) => a.description.localeCompare(b.description));
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went wrong:", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  findUnchangedMedicines(data: any[]): any[] {

    const sortedData = data.slice().sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    const unchangedMedicines = [];

    for (let i = 0; i < sortedData.length - 1; i++) {
      const currentMedicine = sortedData[i];
      const nextMedicine = sortedData[i + 1];

      if (currentMedicine.totalStock === nextMedicine.totalStock) {
        unchangedMedicines.push(currentMedicine);
      }
    }

    return unchangedMedicines;
  }
}
