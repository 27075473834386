<app-amdds-header></app-amdds-header>

<div class="container-fluid">
  <div class="row mb-5"></div>
  <div class="row mb-3"></div>
  <h2 class="bold text-center">Anitha Medical Expiry Stock Details Screen</h2>

  <div class="container mt-3">
    <div class="row d-flex justify-content-end">
      <div class="col-lg-3">
        <div class="form-floating">
          <input class="form-control text-bold" type="text" id="Tamount3" [value]="this.totalAmounts"
            aria-label="readonly input example" readonly>
          <label for="Select4">Total Amount</label>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">

      <div class="col-lg-2">
        <div class="card mt-3">
          <tr>
            <th class="col-1">Select Option <span></span></th>
          </tr>
          <div class="row ms-5 mt-3">
            <div class="form-check ms-3">
              <input type="checkbox" class="form-check-input" id="expiryStockCheckbox" (change)="expiryDetails()">
              <label class="form-check-label" for="expiryStockCheckbox">Already Expired</label>
            </div>
            <div class="form-check ms-3" *ngFor="let days of expiryDays">
              <input class="form-check-input" type="checkbox" id="{{ days }}days" [(ngModel)]="selectedExpiryDays[days]"
                (change)="getExpiry()">
              <label class="form-check-label" for="{{ days }}days">Next {{ days }} Days</label>
            </div>
            <div class="mt-3"></div>
          </div>
        </div>
      </div>

      <div class="col-lg-9 py-1">
        <div class="table-responsive">
          <table class="table">
            <thead class="rounded">
              <tr class="sticky-top">
                <th>S.No</th>
                <th>Description</th>
                <th>Total Stock</th>
                <th>Unit</th>
                <th>Batch Number</th>
                <th>MRP Rate</th>
                <th>Total Values</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody *ngIf="this.filterStockDetails.length > 0; else noDataFound">
              <tr *ngFor="let stock of filterStockDetails| filter: searchText; let i = index">
                <td>{{i + 1}}</td>
                <td>{{stock.description}}</td>
                <td>{{stock.totalStock}}</td>
                <td>{{stock.unit}}</td>
                <td>{{stock.batchNumber}}</td>
                <td>{{stock.mrpRate}}</td>
                <td>{{stock.totalAmount}}</td>
                <td>{{stock.expiryDate}}</td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="11">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>