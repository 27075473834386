<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">New Joining Form</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/NewJoinForm"><b>Form Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/ReportScreen"><b>View Form</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid form mt-5">
  <form (ngSubmit)="onSubmit()" [formGroup]="Newjoin">
    <div class="row mt-1"></div>
    <div class="row mx-2 mt-2">
      <div class="row col-md-12 mt-5">
        <div class="row col-md-4  mt-3">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" class="form-control text-capitalize" formControlName="newjoin_id"
                value="{{currentNewJoinId}}" readonly>
              <label>Employee ID<span style="color:red;">*</span></label>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row col-md-4 mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <select class="form-control" id="epid" formControlName="employee_type" (change)="onEmployee($event)">
                <option value="" disabled selected hidden>
                  Select Employee Type
                </option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
                <option value="Trainee">Trainee</option>
              </select>
              <label class="form-label">Employment Type<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.employee_type" class="text-danger">{{ NewJoinErrors.employee_type }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>


        <div class="row col-md-4 mt-3">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <select formControlName="location" class="form-control">
                <option value="" disabled selected hidden>Select Any One</option>
                <option value="Anitha Medicals">Anitha Medicals</option>
                <option value="Varshini Hospital">Varshini Hospital</option>
                <option value="Lab">Lab</option>
              </select>
              <label>Location<span style="color:red;">*</span>
              </label>
              <span *ngIf="NewJoinErrors.location" class="text-danger">{{ NewJoinErrors.location }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>

      </div>

      <div class="row col-md-12 mt-3">
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <input type="date" formControlName="employee_joining_date" class="form-control text-capitalize"
                [max]="currentDate">
              <span *ngIf="NewJoinErrors.employee_joining_date" class="text-danger">{{
                NewJoinErrors.employee_joining_date }}</span>
              <label>Employee Joining Date<span style="color:red;">*</span></label>

            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" class="form-control text-capitalize" formControlName="employee_name" id="employee_name"
                placeholder="Name">
              <label class="form-label">Employee Name<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.employee_name" class="text-danger">{{ NewJoinErrors.employee_name }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" class="form-control text-capitalize" formControlName="employee_father_name"
                placeholder="Fathers Name">
              <label>Father's Name<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.employee_father_name" class="text-danger">{{ NewJoinErrors.employee_father_name
                }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-12 mt-3">
        <div class="row row col-md-4 mt-2">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <div class="form-group" style="border-radius: 5px !important; border: 1px solid;">
              <label class="ms-2">Gender <span style="color:red;">*</span></label>
              <br>
              <div class="form-check form-check-inline ms-2">
                <input class="form-check-input" type="radio" formControlName="gender" value="Male">
                <label class="form-check-label">Male</label>
              </div>
              <div class="form-check form-check-inline ms-2">
                <input class="form-check-input" type="radio" formControlName="gender" value="Female">
                <label class="form-check-label">Female</label>
              </div>
            </div>
            <span *ngIf="NewJoinErrors.gender" class="text-danger">{{ NewJoinErrors.gender }}</span>
          </div>


          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <div class="form-floating ">
              <input type="date" formControlName="date_of_birth" class="form-control text-capitalize"
                (change)="dateOfBirth($event)" [max]="currentDate">
              <label class="form-label">Date of Birth<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.date_of_birth" class="text-danger">{{ NewJoinErrors.date_of_birth }}</span>
            </div>
          </div>

          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>

          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" formControlName="age" class="form-control text-capitalize" placeholder="Age"
                value="{{age}}" readonly>
              <span *ngIf="NewJoinErrors.age" class="text-danger">{{ NewJoinErrors.age }}</span>
              <label>Age<span style="color:red;">*</span></label>
            </div>
          </div>

        </div>
      </div>

      <div class="row col-12 mt-3">
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <textarea class="form-control" formControlName="designation" placeholder="Designation"></textarea>
              <label>Designation<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.designation" class="text-danger">{{ NewJoinErrors.designation }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <textarea class="form-control" formControlName="qualification" placeholder="Designation"></textarea>
              <label class="form-label">Qualification<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.qualification" class="text-danger">{{ NewJoinErrors.qualification }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <textarea class="form-control" formControlName="address" placeholder="Address"></textarea>
              <label class="form-label">Address<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.address" class="text-danger">{{ NewJoinErrors.address }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-12 mt-3">
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" class="form-control text-capitalize" formControlName="employeelocation"
                placeholder="Location">
              <label>Location <span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.employeelocation" class="text-danger">{{ NewJoinErrors.employeelocation
                }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-floating ">
              <input type="text" formControlName="blood_group" class="form-control text-capitalize"
                placeholder="Bloodgroup">
              <label class="form-label">Blood Group<span style="color:red;">*</span></label>
              <span *ngIf="NewJoinErrors.blood_group" class="text-danger">{{ NewJoinErrors.blood_group }}</span>
            </div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row col-md-4 mt-2" *ngIf="stipendDisable">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="form-control" style="border-radius: 5px !important; border: 1px solid ;">
              <label class="">Stipend for This Trainer ?<span style="color:red;">*</span></label>
              <br>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="Yes" formControlName="stipend"
                  (change)="stipend($event)">
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="No" formControlName="stipend"
                  (change)="stipend($event)">
                <label class="form-check-label">No</label>
              </div>
            </div>
            <span *ngIf="NewJoinErrors.stipend" class="text-danger">{{ NewJoinErrors.stipend }}</span>
          </div>
        </div>
      </div>


      <div class="" *ngIf="trainneDisable">
        <hr style="border: 3px solid">
        <div class="row col-12 mb-3 mt-2">
          <div class="text-center">
            <h5>Trainee Details</h5>
          </div>
        </div>

        <div class="row col-12 mt-3">
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" formControlName="trainer_college_name" class="form-control text-capitalize"
                  placeholder="College Name">
                <label class="form-label">College Name<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.trainer_college_name" class="text-danger">{{
                  NewJoinErrors.trainer_college_name }}</span>
              </div>
            </div>
          </div>
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" class="form-control text-capitalize" formControlName="trainer_college_roll_no"
                  placeholder="College Roll Number">
                <label>College Roll Number<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.trainer_college_roll_no" class="text-danger">{{
                  NewJoinErrors.trainer_college_roll_no }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" class="form-control text-capitalize" formControlName="referencer_name" id=""
                  placeholder="Reference Name">
                <label class="form-label">Reference By<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.referencer_name" class="text-danger">{{ NewJoinErrors.referencer_name
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row col-12 mt-3">
          <div class="row row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="date" class="form-control text-capitalize" formControlName="training_start_date"
                  [max]="currentDate">
                <label class="form-label">Training Start Date<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.training_start_date" class="text-danger">{{ NewJoinErrors.training_start_date
                  }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="date" formControlName="training_end_date" class="form-control text-capitalize">
                <label>Training End Date<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.training_end_date" class="text-danger">{{ NewJoinErrors.training_end_date
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" *ngIf="bankDisable">
        <hr style="border: 3px solid">
        <div class="row col-12 mb-3 mt-2">
          <div class="text-center">
            <h5>Bank Account Details</h5>
          </div>
          <br>
        </div>
        <div class="row col-12 mt-3">
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" formControlName="aadhar_no" class="form-control text-capitalize"
                  placeholder="Aadhar Number" (keypress)="numberOnly($event)" maxlength="12">
                <label class="form-label">Aadhar Number<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.aadhar_no" class="text-danger">{{ NewJoinErrors.aadhar_no }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <select name="bankname" formControlName="bank_name" class="form-control" required>
                  <option selected="selected" value="0">--Select --</option>
                  <option value="ALLAHABAD BANK">ALLAHABAD BANK</option>
                  <option value="ANDHRA BANK">ANDHRA BANK</option>
                  <option value="AXIS BANK">AXIS BANK</option>
                  <option value="BANK OF BARODA">BANK OF BARODA</option>
                  <option value="BANK OF INDIA">BANK OF INDIA</option>
                  <option value="BANK OF MAHARASHTRA">BANK OF MAHARASHTRA</option>
                  <option value="BHARATIYA MAHILA BANK LIMITED">BHARATIYA MAHILA BANK LIMITED</option>
                  <option value="CANARA BANK">CANARA BANK</option>
                  <option value="CENTRAL BANK OF INDIA">CENTRAL BANK OF INDIA</option>
                  <option value="CORPORATION BANK">CORPORATION BANK</option>
                  <option value="DENA BANK">DENA BANK</option>
                  <option value="FEDERAL BANK LTD">FEDERAL BANK LTD</option>
                  <option value="FINO PAYMENT BANK">FINO PAYMENT BANK</option>
                  <option value="GRAMIN VIKASH BANK">GRAMIN VIKASH BANK</option>
                  <option value="HDFC BANK LTD0">HDFC BANK LTD0</option>
                  <option value="ICICI BANK LTD">ICICI BANK LTD</option>
                  <option value="IDBI BANK LTD">IDBI BANK LTD</option>
                  <option value="INDIAN BANK">INDIAN BANK</option>
                  <option value="INDIAN OVERSEAS BANK">INDIAN OVERSEAS BANK</option>
                  <option value="INDUSIND BANK LTD">INDUSIND BANK LTD</option>
                  <option value="KARNATAKA BANK LTD">KARNATAKA BANK LTD</option>
                  <option value="KARUR VYSYA BANK">KARUR VYSYA BANK</option>
                  <option value="KOTAK MAHINDRA BANK">KOTAK MAHINDRA BANK</option>
                  <option value="PAYTM BANK">PAYTM BANK</option>
                  <option value="PUNJAB AND SIND BANK">PUNJAB AND SIND BANK</option>
                  <option value="PUNJAB NATIONAL BANK">PUNJAB NATIONAL BANK</option>
                  <option value="SOUTH INDIAN BANK">SOUTH INDIAN BANK</option>
                  <option value="STATE BANK OF INDIA">STATE BANK OF INDIA</option>
                  <option value="SYNDICATE BANK">SYNDICATE BANK</option>
                  <option value="UCO BANK">UCO BANK</option>
                  <option value="UNION BANK OF INDIA">UNION BANK OF INDIA</option>
                  <option value="UNITED BANK OF INDIA">UNITED BANK OF INDIA</option>
                  <option value="VIJAYA BANK">VIJAYA BANK</option>
                  <option value="YES BANK LTD">YES BANK LTD</option>
                </select>
                <label>Bank Name<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.bank_name" class="text-danger">{{ NewJoinErrors.bank_name }}</span>
              </div>
            </div>
          </div>

          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" formControlName="branch_name" class="form-control text-capitalize"
                  placeholder="Branch Name">
                <label>Branch Name <span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.branch_name" class="text-danger">{{ NewJoinErrors.branch_name }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>

        <div class="row col-12 mt-3">
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" formControlName="bank_ifsc_code" class="form-control text-capitalize"
                  placeholder="IFSC Code">
                <label class="form-label">Bank IFSC Code<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.bank_ifsc_code" class="text-danger">{{ NewJoinErrors.bank_ifsc_code }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" class="form-control text-capitalize" formControlName="name_as_in_Passbook"
                  placeholder="Name as a passbook">
                <label class="form-label">Name as a passbook<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.name_as_in_Passbook" class="text-danger">{{ NewJoinErrors.name_as_in_Passbook
                  }}</span>
              </div>
            </div>
          </div>
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">

                <input type="text" formControlName="account_no" class="form-control text-capitalize"
                  placeholder="Account Number">
                <label>Account Number<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.account_no" class="text-danger">{{ NewJoinErrors.account_no }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>

        <div class="row col-12 mt-3">
          <div class="row row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" formControlName="employee_contact_no" class="form-control text-capitalize"
                  placeholder="Employee Contact" (keypress)="numberOnly($event)" maxlength="10">
                <label class="form-label">Employee contact<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.employee_contact_no" class="text-danger">{{ NewJoinErrors.employee_contact_no
                  }}</span>
              </div>
            </div>
          </div>
          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" class="form-control text-capitalize" formControlName="emergency_contact_no"
                  placeholder="Emergency Number" (keypress)="numberOnly($event)" maxlength="10">
                <label>Emergency Number<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.emergency_contact_no" class="text-danger">{{
                  NewJoinErrors.emergency_contact_no }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>

          <div class="row col-md-4 mt-2">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="form-floating ">
                <input type="text" class="form-control text-capitalize" formControlName="salary_value_fixed"
                  placeholder="Salary Value Fixed">
                <label class="form-label">Salary Value Fixed<span style="color:red;">*</span></label>
                <span *ngIf="NewJoinErrors.salary_value_fixed" class="text-danger">{{ NewJoinErrors.salary_value_fixed
                  }}</span>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
        </div>
      </div>

      <div class="card-body text-center mt-2">
        <button *ngIf="saveButton" type="submit" class="btn btn-success ml-4"><span
            style="margin:center;">Save</span></button>
        <button type="button" class="btn btn-primary ml-5" *ngIf="nextButton" (click)="Navigate()"><span
            style="margin:center;">Next</span></button>
      </div>

      <div class="row mt-5"></div>
    </div>
  </form>
</div>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>