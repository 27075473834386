<app-amdds-header></app-amdds-header>
<div class="row mt-3"></div>
<div class="container-fluid mt-5">
    <form (ngSubmit)="onSubmit()" [formGroup]="PatientForm">
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="card ">
                    <div class="overflow-auto p-3" style="max-width: 1020px; max-height: 520px;">
                        <h3 class="text-center">OP Patient Count</h3>
                        <div class="text-end">
                            <button type="button" class="btn-primary" id="savebutton " (click)="open()">Add
                                Doctor</button>&nbsp;&nbsp;
                        </div>

                        <div class="container-fluid">
                            <div class="row mt-2"></div>
                            <div class="row">
                                <div class="row mt-3">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label><strong>Date</strong></label>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group mt-3">
                                            <input type="date" class="form-control rounded-0txt" (change)="getDate()"
                                                formControlName="amFormDate" name="Formdate">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="row mt-3">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label formControlName="amDoctorName"><strong>Dr.BMP</strong></label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <div class="form-group">
                                            <input type="number" class="form-control rounded-0 txt"
                                                formControlName="amPatientCount" name="Patientcount1"
                                                placeholder="Patient Count" (change)="getPatientCount()">
                                        </div>
                                    </div>
                                    <div class="col-md-1 mt-4" *ngIf="icon" data-toggle="tooltip" data-placement="top"
                                        title="Add Visited doctor">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                            (click)="addInput()" fill="currentColor"
                                            class="bi bi-plus-circle-fill text-primary" viewBox="0 0 16 16">
                                            <path
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="doctorDetails">
                                <div class="row mt-3" *ngFor="let ep of this.multiIncidental">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <label><strong>Select Doctor</strong></label>
                                        <input class="form-control" id="dropDown" value="{{ep.amDoctorName}}">
                                    </div>
                                    <div class="col-md-6 mt-5">
                                        <div class="form-group py-2">
                                            <input type="number" class="form-control rounded-0 txt"
                                                value="{{ep.amPatientCount}}" name="Patientcount1"
                                                placeholder="Patient Count">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="multpleDoctors">
                                <div class="row mt-3" *ngFor="let ep of this.multiIncidental">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label><strong>Doctor Name</strong></label>
                                            <select class="form-control" id="dropDown" (change)="multiValue(ep,$event)">
                                                <option class="select">Select Doctor Name</option>
                                                <option *ngFor="let ep of this.getAllDoctorName"
                                                    value="{{ep.doctorName}}">
                                                    {{ep.doctorName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group py-2">
                                            <input type="number" class="form-control rounded-0 txt"
                                                (change)="multiinput(ep,$event)" name="Patientcount1"
                                                placeholder="Patient Count">
                                        </div>
                                    </div>
                                    <div class="col-md-1">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="row mt-3">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4 mt-3">
                                        <div class="form-group">
                                            <label><strong>Total Cases</strong></label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="form-group mt-2">
                                            <input type="number" class="form-control rounded-0 txt"
                                                formControlName="amTotalCases" name="In-Patient"
                                                placeholder="Total Case Count">
                                        </div>
                                    </div>
                                    <div class="col-md-6  ">
                                        <div class="form-group mt-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6 mt-4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="row mt-1">
                        <div class="row" style="margin:0px;">
                            <div class="col-md-1"></div>

                            <div class="col-md-4 mt-3">
                                <div class="form-group">
                                    <label><strong>Total In-Patient</strong></label>
                                </div>
                            </div>
                            <div class="col-md-6 mt-3">
                                <div class="form-group">
                                    <input type="text" class="form-control rounded-0 txt"
                                        formControlName="amTotalInPatient" name="In-Patient" placeholder="Patient Count"
                                        readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="text-center mt-3">IP Room/General Ward Utilization</h3>
                    <div class="row mt-3">
                        <div class="row" style="margin:0px;">
                            <div class="col-md-1"></div>
                            <div class="col">
                                <div class="form-group">
                                    <label><strong>General Ward</strong></label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" style="margin:0px;">
                            <div class="col-md-1"></div>
                            <div class="col mt-">
                                <div class="form-group">
                                    <input type="checkbox" id="gwbed1" name="gwbed1" formControlName="amGeneralRoom1"
                                        (change)="totalamGeneralRoom1()">&nbsp;&nbsp;
                                    <label for="gwbed1">Bed 1</label>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amGeneralRoom2"
                                        (change)="totalamGeneralRoom2()">&nbsp;&nbsp;
                                    <label>Bed 2</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amGeneralRoom3"
                                        (change)="totalamGeneralRoom3()">&nbsp;&nbsp;
                                    <label>Bed 3</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" style="margin:0px;">
                            <div class="col-md-1"></div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed1" formControlName="amGeneralRoom4"
                                        (change)="totalamGeneralRoom4()">&nbsp;&nbsp;
                                    <label for="gwbed1">Bed 4</label>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amGeneralRoom5"
                                        (change)="totalamGeneralRoom5()">&nbsp;&nbsp;
                                    <label>Bed 5</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amGeneralRoom6"
                                        (change)="totalamGeneralRoom6()">&nbsp;&nbsp;
                                    <label>Bed 6</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3" style="margin:0px;">
                            <div class="col-md-1"></div>
                            <div class="col">
                                <div class="form-group">
                                    <label><strong>IP Allocated Room</strong></label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3" style="margin:0px;">
                            <div class="col-md-1"></div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed1" formControlName="amRoom101"
                                        (change)="totalamRoom101()">&nbsp;&nbsp;
                                    <label for="gwbed1">IP Room 101</label>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amRoom102"
                                        (change)="totalamRoom102()">&nbsp;&nbsp;
                                    <label>IP Room 103</label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <input type="checkbox" name="gwbed2" formControlName="amRoom104"
                                        (change)="totalamRoom104()">
                                    <label>IP Room 104</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-4"></div>
                        <h3 class="text-center">Surgery Details</h3>

                        <div class="row mt-1">
                            <div class="row" style="margin:0px;">
                                <div class="col-md-1"></div>

                                <div class="col-md-4 mt-3">
                                    <div class="form-group">
                                        <label><strong>Minor Surgery</strong></label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <div class="form-group">
                                        <input type="number" class="form-control rounded-0 txt"
                                            formControlName="amSurgeryMinorPatient" name="Patientcount1"
                                            placeholder="Minor Surgery" onchange="addtotalcount();">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="row" style="margin:0px;">
                                <div class="col-md-1"></div>

                                <div class="col-md-4 mt-3">
                                    <div class="form-group">
                                        <label><strong>Major Surgery</strong></label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <div class="form-group">
                                        <input type="number" class="form-control rounded-0 txt" name="Patientcount1"
                                            formControlName="amSurgeryMajorPatient" placeholder="Major Surgery"
                                            onchange="addtotalcount();">

                                    </div>
                                </div>
                                <div class="form-group mt-5"></div>
                                <div class="form-group mt-3"></div>
                            </div>
                        </div>
                        <div class="form-group mt-2"></div>
                    </div>

                </div>
            </div>
        </div>
        <h3 class="text-center">Comments</h3>
        <div class="form-group">
            <textarea type="text" class="form-control rounded-0 txt" formControlName="amComments"
                style="height: 70px "></textarea>
            <span *ngIf="PatientFormError.amComments" class="text-danger">{{PatientFormError.amComments}}</span>
        </div>
        <div class="text-center p-2">

            <button type="submit" class="btn btn-primary" id="savebutton">Save</button>&nbsp;&nbsp;
            <button class="btn btn-danger" id="rejectbutton" (click)="cancel()">Cancel</button>
        </div>

    </form>
</div>

<div class="modal" role="document" [style.display]="showPopUp ? 'block' : 'none'">
    <form [formGroup]="doctor">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="text-center">Add New Doctor</h1>
                </div>
                <div class="modal-body">
                    <div class="col-md-12">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="doctorName"
                                placeholder="Enter New Doctor Name">
                            <label>Doctor Name<span class="text-danger">*</span> </label>

                        </div>
                        <span *ngIf="Error.doctorName" class="text-danger">{{Error.doctorName}}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" data-dismiss="modal" (click)="valid()"><b>Add New
                            Doctor</b></button> &emsp;
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="close()"><b>Close</b>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="row mt-5"></div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>