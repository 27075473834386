<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Vendor Payment Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item" *ngIf=userDisable>
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/AddVendor"><b>Add New Vendor</b></a>
        </li>
        <li class="nav-item" *ngIf=userDisable>
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/HospitalVendor"><b>View Vendor</b></a>
        </li>
        <li class="nav-item" *ngIf=userDisable>
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/OldInvoice"><b>Invoice Table</b></a>
        </li>
        <li class="nav-item" *ngIf=userDisable>
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/VendorPayment"><b>Vendor Payment</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/OverAll-VendorPayment"><b>Over All Vendor Payment Details</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>