import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { NewJoinForms } from "@/model/new_JoinForms";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-new-joining-forms",
  templateUrl: "./new-joining-forms.component.html",
  styleUrls: ["./new-joining-forms.component.scss"],
  providers: [DatePipe]
})
export class NewJoiningFormsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  getFormId: NewJoinForms[] = [];
  currentNewJoinId: string[] = [];
  trainneDisable = false;
  stipendDisable = false;
  bankDisable = true;
  newJoiningDeatils: NewJoinForms | undefined;
  age: string = "";
  birthdate: Date | null = null;
  nextButton = false;
  saveButton = true;
  currentDate: string | null = null;



  constructor(private router: Router, private appService: AppService, private datePipe: DatePipe,
    private spinner: NgxSpinnerService) {
    this.currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd") || null;
  }

  NewJoin2 = new UntypedFormGroup({});

  Newjoin = new UntypedFormGroup(
    {
      employee_type: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      location: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      newjoin_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      employee_joining_date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      employee_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      employee_father_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      gender: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      date_of_birth: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      age: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      designation: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      qualification: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      address: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      employeelocation: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      blood_group: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      aadhar_no: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]),
      bank_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      branch_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      bank_ifsc_code: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      name_as_in_Passbook: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      account_no: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      employee_contact_no: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      emergency_contact_no: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      salary_value_fixed: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      trainer_college_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      trainer_college_roll_no: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      referencer_name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      training_start_date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      training_end_date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
      stipend: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
    }
  );
  public NewJoinErrors = {
    location: "",
    employee_type: "",
    employee_joining_date: "",
    employee_name: "",
    employee_father_name: "",
    gender: "",
    date_of_birth: "",
    age: "",
    designation: "",
    qualification: "",
    address: "",
    employeelocation: "",
    blood_group: "",
    aadhar_no: "",
    bank_name: "",
    branch_name: "",
    bank_ifsc_code: "",
    name_as_in_Passbook: "",
    account_no: "",
    employee_contact_no: "",
    emergency_contact_no: "",
    salary_value_fixed: "",
    trainer_college_name: "",
    trainer_college_roll_no: "",
    referencer_name: "",
    training_start_date: "",
    training_end_date: "",
    stipend: ""
  };

  ngOnInit() {
    this.getJoinID();
  }

  onSubmit() {

    this.formValidation();

  }

  formValidation() {
    this.NewJoinErrors.location = "";
    this.NewJoinErrors.employee_type = " ";
    this.NewJoinErrors.employee_joining_date = "";
    this.NewJoinErrors.employee_name = "";
    this.NewJoinErrors.employee_father_name = "";
    this.NewJoinErrors.gender = "";
    this.NewJoinErrors.date_of_birth = "",
      this.NewJoinErrors.age = "";
    this.NewJoinErrors.designation = "";
    this.NewJoinErrors.qualification = "";
    this.NewJoinErrors.address = "";
    this.NewJoinErrors.employeelocation = "";
    this.NewJoinErrors.blood_group = "";
    this.NewJoinErrors.aadhar_no = "";
    this.NewJoinErrors.bank_name = "";
    this.NewJoinErrors.branch_name = "";
    this.NewJoinErrors.bank_ifsc_code = "";
    this.NewJoinErrors.name_as_in_Passbook = "";
    this.NewJoinErrors.account_no = "";
    this.NewJoinErrors.employee_contact_no = "";
    this.NewJoinErrors.emergency_contact_no = "";
    this.NewJoinErrors.salary_value_fixed = "";
    this.NewJoinErrors.trainer_college_name = "";
    this.NewJoinErrors.trainer_college_roll_no = "";
    this.NewJoinErrors.referencer_name = "";
    this.NewJoinErrors.training_start_date = "";
    this.NewJoinErrors.training_end_date = "";
    this.NewJoinErrors.stipend = "";
    let hasError = false;

    if (this.Newjoin.get("employee_type")?.invalid) {
      this.NewJoinErrors.employee_type = "*Employee type is Required";
      hasError = true;
    }
    if (this.Newjoin.get("location")?.invalid) {
      this.NewJoinErrors.location = "*location is Required";
      hasError = true;
    }
    if (this.Newjoin.get("employee_joining_date")?.invalid) {
      this.NewJoinErrors.employee_joining_date = "*Employee joining date is Required";
      hasError = true;
    }
    if (this.Newjoin.get("employee_name")?.invalid) {
      this.NewJoinErrors.employee_name = "*Employee name is Required";
      hasError = true;
    }
    if (this.Newjoin.get("employee_father_name")?.invalid) {
      this.NewJoinErrors.employee_father_name = "*Employee Father's name is Required";
      hasError = true;
    }

    if (this.Newjoin.get("gender")?.invalid) {
      this.NewJoinErrors.gender = "*Gender is Required";
      hasError = true;
    }

    if (this.Newjoin.get("date_of_birth")?.invalid) {
      this.NewJoinErrors.date_of_birth = "*Date of birth is Required";
      hasError = true;
    }

    if (this.Newjoin.get("age")?.invalid) {
      this.NewJoinErrors.age = "*Age is Required";
      hasError = true;
    }

    if (this.Newjoin.get("designation")?.invalid) {
      this.NewJoinErrors.designation = "*Designation is Required";
      hasError = true;
    }

    if (this.Newjoin.get("qualification")?.invalid) {
      this.NewJoinErrors.qualification = "*Qualification is Required";
      hasError = true;
    }

    if (this.Newjoin.get("address")?.invalid) {
      this.NewJoinErrors.address = "*Address is Required";
      hasError = true;
    }

    if (this.Newjoin.get("employeelocation")?.invalid) {
      this.NewJoinErrors.employeelocation = "*Employee location is Required";
      hasError = true;
    }

    if (this.Newjoin.get("blood_group")?.invalid) {
      this.NewJoinErrors.blood_group = "*Blood group is Required";
      hasError = true;
    }
    if (this.Newjoin.get("employee_type")?.value !== "Trainee" || this.Newjoin.get("stipend")?.value == "Yes") {
      if (this.Newjoin.get("aadhar_no")?.invalid) {
        if (this.Newjoin.get("aadhar_no")?.errors?.["required"]) {
          this.NewJoinErrors.aadhar_no = "*Aadhar no is Required";
        }
        if (this.Newjoin.get("aadhar_no")?.getError("pattern")) {
          this.NewJoinErrors.aadhar_no = "*Enter a 12-digit Aadhar number.";
        }
        hasError = true;
      }
      if (this.Newjoin.get("bank_name")?.invalid) {
        this.NewJoinErrors.bank_name = "*Bank name isRequired";
        hasError = true;
      }
      if (this.Newjoin.get("branch_name")?.invalid) {
        this.NewJoinErrors.branch_name = "*Branch name is Required";
        hasError = true;
      }
      if (this.Newjoin.get("bank_ifsc_code")?.invalid) {
        this.NewJoinErrors.bank_ifsc_code = "*Bank ifsc code is Required";
        hasError = true;
      }
      if (this.Newjoin.get("name_as_in_Passbook")?.invalid) {
        this.NewJoinErrors.name_as_in_Passbook = "*Name as in Passbook is Required";
        hasError = true;
      }
      if (this.Newjoin.get("account_no")?.invalid) {
        this.NewJoinErrors.account_no = "*account_no Required";
        hasError = true;
      }

      if (this.Newjoin.get("employee_contact_no")?.invalid) {
        if (this.Newjoin.get("employee_contact_no")?.errors?.["required"]) {
          this.NewJoinErrors.employee_contact_no = "*Phone Number is required";
        }
        if (this.Newjoin.get("employee_contact_no")?.getError("pattern")) {
          this.NewJoinErrors.employee_contact_no = "*Enter a 10-digit Phone number.";
        }
        hasError = true;
      }

      if (this.Newjoin.get("emergency_contact_no")?.invalid) {
        if (this.Newjoin.get("emergency_contact_no")?.errors?.["required"]) {
          this.NewJoinErrors.emergency_contact_no = "*Emergency contact no is Required";
        }
        if (this.Newjoin.get("emergency_contact_no")?.getError("pattern")) {
          this.NewJoinErrors.emergency_contact_no = "*Enter a 10-digit Phone number.";
        }
        hasError = true;
      }

      if (this.Newjoin.get("salary_value_fixed")?.invalid) {
        this.NewJoinErrors.salary_value_fixed = "*Salary value fixed is Required";
        hasError = true;
      }
    }
    if (this.Newjoin.get("employee_type")?.value == "Trainee") {
      if (this.Newjoin.get("trainer_college_name")?.invalid) {
        this.NewJoinErrors.trainer_college_name = "*Trainer college name is Required";
        hasError = true;
      }
      if (this.Newjoin.get("trainer_college_roll_no")?.invalid) {
        this.NewJoinErrors.trainer_college_roll_no = "*Trainer college roll no is Required";
        hasError = true;
      }
      if (this.Newjoin.get("referencer_name")?.invalid) {
        this.NewJoinErrors.referencer_name = "*Reference by is Required";
        hasError = true;
      }
      if (this.Newjoin.get("training_start_date")?.invalid) {
        this.NewJoinErrors.training_start_date = "*Training start data is Required";
        hasError = true;
      }
      if (this.Newjoin.get("training_end_date")?.invalid) {
        this.NewJoinErrors.training_end_date = "*Training end data is Required";
        hasError = true;
      }
      if (this.Newjoin.get("stipend")?.invalid) {
        this.NewJoinErrors.stipend = "*Stipend Details is Required";
        hasError = true;
      }
    }
    if (!hasError) {
      this.saveNewJoining();
    }

  }


  saveNewJoining() {
    this.spinner.show();
    this.Newjoin.controls["newjoin_id"].setValue(this.currentNewJoinId[0]);
    this.appService
      .saveNewJoining(this.Newjoin.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // @ts-ignore
        this.newJoiningDeatils = data;
        this.spinner.hide();
        this.SuccessAlert();
        var newEmployee: string | undefined;
        var newEmployeeType: string | undefined;
        localStorage.setItem("newJoiningId", this.currentNewJoinId[0]);
        newEmployee = this.newJoiningDeatils?.employee_name;
        newEmployeeType = this.newJoiningDeatils?.employee_type;
        // @ts-ignore
        localStorage.setItem("newEmploeeName", newEmployee);
        // @ts-ignore
        localStorage.setItem("employeeType", newEmployeeType);
      }, (err: any) => {
        console.log("something went wrong:", err);
        this.spinner.hide();
        this.errorAlert();
      },
        () => console.log("HTTP request completed."));
  }


  getJoinID() {
    this.appService.getJoinID()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getFormId = data;
        var newJoinId: string[] = [];
        for (var ep of this.getFormId) {
          newJoinId.push(ep.newjoin_id);
        }
        this.currentNewJoinId = newJoinId;
      });
  }

  onEmployee(event: any) {
    const value = event.target.value;
    if (value == "Trainee") {
      this.trainneDisable = true;
      this.stipendDisable = true;
      this.bankDisable = false;
    } else {
      this.trainneDisable = false;
      this.stipendDisable = false;
      this.bankDisable = true;
    }
  }

  stipend(event: any) {
    const value = event.target.value;
    if (value == "Yes") {
      this.bankDisable = true;
      localStorage.setItem("stipend", value);
    } else {
      this.bankDisable = false;
      localStorage.setItem("stipend", value);
    }
  }

  dateOfBirth(event: any) {
    const value: string = event.target.value;
    this.birthdate = new Date(value);
    if (this.birthdate) {
      const timeDiff: number = Date.now() - this.birthdate.getTime();
      const ageDate: Date = new Date(timeDiff);
      //@ts-ignore
      this.age = Math.abs(Math.floor(ageDate.getTime() / (1000 * 60 * 60 * 24 * 365.25)));
      this.Newjoin.get("age")?.setValue(this.age);
    }
  }

  Navigate() {
    this.router.navigate(["/FileUpload"]);
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  SuccessAlert() {
    Swal.fire({
      title: "Your Registration Saved Sucessfully,Click Next Button For Document Upload",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Next",
      showClass: {
        popup: "animate_animated animate_fadeInDown"
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/FileUpload"]);
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown"
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
}
