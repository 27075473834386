import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup } from "@angular/forms";
import { scanDoctorPayments } from "@/model/scanDoctorPayments";


@Component({
  selector: "app-scan-center-doctor-payment",
  templateUrl: "./scan-center-doctor-payment.component.html",
  styleUrls: ["./scan-center-doctor-payment.component.scss"]
})
export class ScanCenterDoctorPaymentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: String;
  searchText: any;
  UniqueData: any [] = [];
  getDoctorPayment: scanDoctorPayments[] = [];
  getFilterDoctorFees: scanDoctorPayments[] = [];
  getScanDoctorAmount: number = 0;
  getReferenceAmount: number = 0;
  getNikitaScanFees: number = 0;
  paymentTypes = ['Scan Doctor', 'Ref Doctor', 'Nikhitha Scan'];
  scanPayment: FormGroup;
  onShowScanDetails: boolean = false;
  onShowDoctorPayments: boolean = false;
  scanDoctorFees: string | null = null;
  refDoctorFees: string | null = null;
  nikhithaScanFees: string | null = null;


  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService, private fb: FormBuilder,) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }




  ngOnInit(): void {
    this.getScanDoctorPaymentDetails()
  }


  onDate(): void {
    const fromDate = (document.getElementById('fromDate') as HTMLInputElement).value;
    const toDate = (document.getElementById('toDate') as HTMLInputElement).value;

    if (fromDate && toDate) {
      this.getFilterDoctorFees = this.getDoctorPayment.filter(item => 
        item.paidDate >= fromDate && item.paidDate <= toDate
      ).sort((a, b) => new Date(b.paidDate).getTime() - new Date(a.paidDate).getTime());
    } else {
      this.getFilterDoctorFees = this.getDoctorPayment
    }
  }

  getScanDoctorPaymentDetails() {
    this.apiService.getScanDoctorPayment()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getDoctorPayment = data.sort((a, b) => b.doctorScanId - a.doctorScanId);
        this.getFilterDoctorFees = this.getDoctorPayment
        console.log("testt", data)
      }, (error: any) => {
        console.log(error.message);
        this.toastr.error(error.massage)
      })
  }


  updateScanFilter(event: any, filterType: string) {
    const value = event.target.value;
    this.getFilterDoctorFees = this.getDoctorPayment;
    if (filterType === "scanDoctor") {
      this.scanDoctorFees = (this.scanDoctorFees === "scanDoctor") ? null : "scanDoctor";
      if (this.scanDoctorFees) {
        const getDepotDetails = this.getDoctorPayment.filter(item => item.referenceType === "Scan Doctor");
        this.getFilterDoctorFees = getDepotDetails
      }
      this.refDoctorFees = null;
      this.nikhithaScanFees = null;
    } else if (filterType === "refDoctor") {
      this.refDoctorFees = (this.refDoctorFees === "refDoctor") ? null : "refDoctor";
      if (this.refDoctorFees) {
        const getRefDoctorFees = this.getDoctorPayment.filter(item => item.referenceType === "Ref Doctor");
        this.getFilterDoctorFees = getRefDoctorFees;
      }
      this.scanDoctorFees = null;
      this.nikhithaScanFees = null;
    } else if (filterType === "nikhithaScan") {
      this.nikhithaScanFees = (this.nikhithaScanFees === "nikhithaScan") ? null : "nikhithaScan";
      if (this.nikhithaScanFees) {
        const getNikhithaScanFees = this.getDoctorPayment.filter(item => item.referenceType === "Nikhitha Scan");
        this.getFilterDoctorFees = getNikhithaScanFees;
      }
      this.scanDoctorFees = null;
      this.refDoctorFees = null;
    }
  }

}
