import { Component, HostBinding, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { NewJoinForms } from "@/model/new_JoinForms";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { printDataService } from "@/model/printDataService";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-new-joining-form-report",
  templateUrl: "./new-joining-form-report.component.html",
  styleUrls: ["./new-joining-form-report.component.scss"]
})
export class NewJoiningFormReportComponent implements OnInit {
  searchText: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  datePipe: DatePipe = new DatePipe("en-IN");
  getAllEmployees: NewJoinForms[] = [];
  employeeData: NewJoinForms[] = [];
  entryEdit: NewJoinForms | undefined;
  isMapShow = false;
  isViewShow = false;
  bankDetails = true;
  trainerDisable = true;
  imageUrl: string = "";
  imgPlace: HTMLElement | undefined;
  filteredEmployees: NewJoinForms[];
  currentDate: string | null = null;
  fileType: string = "";
  disablenav = true;

  constructor(private appService: AppService, private tostar: ToastrService,
    private spinner: NgxSpinnerService, private dataService: printDataService,
    private router: Router) {
    this.filteredEmployees = this.getAllEmployees;
    this.currentDate = this.datePipe.transform(new Date(), "yyyy-MM-dd") || null;
  }

  ngOnInit() {
    this.getJoinDetails();
  }

  getJoinDetails() {
    this.spinner.show();
    this.appService.getJoinDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getAllEmployees = data;
        this.spinner.hide();
        var localgetdata: NewJoinForms[] = [];
        var localFormId: string[] = [];
        for (let ep of this.getAllEmployees) {
          localgetdata.push(ep);
          localFormId.push(ep.newjoin_id);
        }
        this.getAllEmployees = localgetdata.sort((a, b) => {
          const idA = parseInt(a.newjoin_id.split("-")[1]);
          const idB = parseInt(b.newjoin_id.split("-")[1]);
          return idA - idB;
        });
      }, (error: any) => {
        this.spinner.hide();
        console.log("something went wrong:", error);
      });
  }

  photoView(id: string, fileName: string) {
    let filePath: string = "";
    for (let details of this.getAllEmployees) {
      if (id == details.newjoin_id) {
        if (fileName == "aadharCard") {
          filePath = details.aadhar_card;
        } else if (fileName == "bankPassBook") {
          filePath = details.bank_passbook;
        } else if (fileName == "educationCertificate") {
          filePath = details.course_completion_certificates;
        } else if (fileName == "photo") {
          filePath = details.employee_photo;
        } else if (fileName == "experienceCertificate") {
          filePath = details.experience_certificate;
        } else if (fileName == "collegeIdCard") {
          filePath = details.college_id_card;
        } else if (fileName == "collegeLetter") {
          filePath = details.collegeLetter;
        }

        let fileType: string = "";
        if (filePath.includes("pdf")) {
          fileType = "application/pdf";
        } else if (filePath.includes("jpg") || filePath.includes("jpeg") ||
          filePath.includes("png")) {
          fileType = "image/jpg";
        }
        this.fileType = fileType;
      }
    }
    this.spinner.show();
    this.appService.fileGet(id, fileName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const mimeType = this.fileType;
        const myBlob = new Blob([data], { type: mimeType });
        window.open(URL.createObjectURL(myBlob), "_blank");
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        this.tostar.error("Something Went Wrong or No Document Upload");
      });
  }

 
  close() {
    this.isMapShow = false;
  }

  openModel(newJoinId: String) {
    this.disablenav = false;
    this.spinner.show();
    var viewData: NewJoinForms | undefined;

    for (let model of this.getAllEmployees) {
      if (newJoinId == model.newjoin_id) {
        viewData = model;
        this.spinner.hide();
        if (model.employee_type == "Trainne" || model.stipend == "Yes") {
          this.trainerDisable = true;
          this.bankDetails = true;
        } else if (model.stipend == "No") {
          this.bankDetails = false;
          this.trainerDisable = true;
        } else if (model.employee_type !== "Trainne") {
          this.bankDetails = true;
          this.trainerDisable = false;
        }
      }
    }
    //@ts-ignore
    this.employeeData.push(viewData);
    this.isViewShow = true;
  }

  selectUlr(newJoinId: string) {
    for (var data of this.getAllEmployees) {
      const allDetails = data;
      var localdate: string | undefined;
      if (data.newjoin_id == newJoinId) {
        this.entryEdit = allDetails;
        localdate = data.training_end_date;
        if (localdate == this.currentDate || localdate <= this.currentDate!) {
          this.router.navigate(["Training"]);
        } else {
          this.tostar.error("Training was not completed");
        }
      }

    }
  }

  ngOnDestroy(): void {
    this.dataService.printData = this.entryEdit;
  }

  close1() {
    this.isViewShow = false;
    this.employeeData = [];
    this.trainerDisable = false;
    window.location.reload();
  }
}
