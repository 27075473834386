<app-amdds-header></app-amdds-header>
<app-vendor-navbar></app-vendor-navbar>

<div class="container-fluid">
    <div class="row py-5"></div>
    <div class="row py-2">
      <div class="col-md-1"></div>
      <div class="col-md-3 mt-2">
        <div class="form-floating">
          <select class="form-select" id="Select" 
            aria-label="Floating label select" (change)="updateStock($event, 'vendor')">
            <option selected>Vendor Name</option>
            <option *ngFor="let name of this.vendorName"> {{name}} </option>
          </select>
          <label for="Select">Select Vendor Name</label>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-2"></div>
      <div class="col-md-3 mt-2">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Search.." [(ngModel)]="searchText">
          <label>Search</label>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row mt-1">
        <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
          <table class="table table-sm table-bordered text-center table-hover mb-0">
              <thead class="header sticky-top">
              <tr>
                <th>S.NO</th>
                <th>Vendor Name</th>
                <th>Bank Name</th>
                <th>Account No</th>
                <th>IFSC Code</th>
                <th>GST No</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody *ngIf="this.finalVendorDetails.length > 0; else noDataFound">
              <tr *ngFor="let table of this.finalVendorDetails|filter: searchText; let i=index">
                <td> {{ i+1 }} </td>
                <td>{{ table.vendorName}}</td>
                <td>{{ table.bankName }}</td>
                <td>{{ table.bankAccountNo }}</td>
                <td>{{ table.bankIfscCode }}</td>
                <td>{{ table.gstNo }}</td>
                <td>{{ table.city }}</td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
</div>

<app-amdds-footer></app-amdds-footer>
