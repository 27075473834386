import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AddStockDetails } from "@/model/addStockDetails";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-stocklist",
  templateUrl: "./stocklist.component.html",
  styleUrls: ["./stocklist.component.scss"]
})
export class StockListComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dailyStockDetails: AddStockDetails[] = [];
  allStcokDetails: AddStockDetails[] = [];
  currentDate: any;
  getDate: string = "";
  searchText: any;
  isViewShow = false;


  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }


  ngOnInit(): void {
    this.getDailyStock(this.currentDate);
  }

  date(event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);
    if (!isNaN(parsedDate.getTime())) {
      const values = this.datePipe.transform(parsedDate, "yyyy-MM-dd") ?? "";
      this.getDate = values;
      this.getDailyStock(this.getDate);
    } else {
      console.error("Invalid date format");
    }
  }


  getDailyStock(date: string) {
    this.spinner.show();
    this.apiService.getDailyStockDetails(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        const groupedStockDetails: { [description: string]: AddStockDetails } = {};
        data.forEach(stock => {
          const description = stock.description;
          if (!groupedStockDetails[description]) {
            groupedStockDetails[description] = { ...stock };
          } else {
            groupedStockDetails[description].totalStock += stock.totalStock;
          }
        });
        const localdate: AddStockDetails[] = Object.values(groupedStockDetails);
        this.spinner.hide()
        if (localdate.length == 0) {
          this.toastr.error("No records found");
        }
        this.dailyStockDetails = localdate.sort((a, b) => {
          const idA = a.stockId;
          const idB = b.stockId;
          return idA - idB;
        });
      }, (err: any) => {
        this.spinner.hide()
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  desc(descrpition: any) {
    const value = descrpition.description;
    this.isViewShow = true;
    this.spinner.show()
    this.apiService.getAmDailyDescripyion(value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        var localData: AddStockDetails[] = []
        for (let dt of data) {
          localData.push(dt);
        }
        this.allStcokDetails = localData.sort((a, b) => (Number(b.stockId) - Number(a.stockId)))
      }, (err: any) => {
        this.spinner.hide()
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  close() {
    this.allStcokDetails = []
    this.isViewShow = false
  }

}
