<app-amdds-header></app-amdds-header>
<app-vendor-navbar></app-vendor-navbar>
<div class="container-fluid">
  <div class="row mb-4"></div>
  <div class="row py-5"></div>

  <section>
    <div class="row ml-1 mr-1">
      <div class="col-md-2 mt-2">
        <div class="form-floating">
          <select class="form-select" id="Select" (change)="updateStock($event, 'vendor')"
            aria-label="Floating label select">
            <option selected>Vendor</option>
            <option *ngFor="let name of this.vendorName"> {{name}} </option>
          </select>
          <label for="Select">Vendor Name</label>
        </div>
      </div>
      <div class="col-md-2 mt-2">
        <div class="form-floating">
          <select class="form-select" id="Select1" (change)="updateStock($event, 'date')"
            aria-label="Floating label select">
            <option selected>Select Date</option>
            <option *ngFor="let name of this.uniqueDates"> {{name}} </option>
          </select>
          <label for="Select1">Invoice Date</label>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-floating mt-2">
          <select class="form-select" id="Select2" aria-label="Floating label select"
            (change)="updateStock($event, 'status')">
            <option selected>Select Option</option>
            <option *ngFor="let status of this.adminApproval" [value]="status">{{ status === 'Agree' ? 'Approved' :
              'Pending' }}</option>
          </select>
          <label for="Select2">Invoice Status</label>
        </div>
      </div>
      <div class="col-md-2">
        <button type="button" class="btn btn-primary mt-3 text-center" [disabled]="isDisabled"
          (click)="UpdateAlert()">Approved All</button>
      </div>
      <div class="col-md-1 mt-3">
        <button type="button" class="btn btn-secondary">{{this.count}}</button>
      </div>
      <div class="col-md-3">
        <label for="formFile" class="form-label" style="font-size: 14px;">Upload Files</label>
        <input class="form-control" [(ngModel)]="selectedFiles" (change)="onFileSelected($event)" type="file"
          id="formFile" multiple>
      </div>
    </div>
  </section>

  <div class="row mt-3">
    <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
      <table class="table table-sm table-bordered text-center table-hover mb-0">
        <thead class="header sticky-top">
          <tr>
            <th>S.NO</th>
            <th>Vendor Name</th>
            <th>Invoice No</th>
            <th>Invoice Date</th>
            <th>Invoice Amount</th>
            <th>Admin Approval</th>
          </tr>
        </thead>
        <tbody *ngIf="this.stock.length > 0; else noDataFound">
          <tr *ngFor="let table of this.stock; let i=index">
            <td> {{ i+1 }} </td>
            <td>{{ table.vendorName}}</td>
            <td>{{ table.invoiceNo }}</td>
            <td>{{ table.date }}</td>
            <td>{{ table.invoiceAmount | currency:'INR':'' }}</td>
            <td><i class="fas fa-check" style="color: green;" *ngIf="table.adminStatus == 'Agree'"></i>
              <button type="button" *ngIf="table.adminStatus == 'Not Agree'" (click)="pendingUpdate(table.stockId)"
                class="btn btn-primary hidestatus" [disabled]="disabledStatus[table.stockId]"
                [ngStyle]="{'color': disabledStatus[table.stockId] ? 'white' : 'inherit', 'background-color': disabledStatus[table.stockId] ? 'black' : 'primary'}">Pending</button>
            </td>
          </tr>
        </tbody>
        <ng-template #noDataFound>
          <tr>
            <td colspan="6">Data Not Found</td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" [style.display]="isModalShow ? 'block' : 'none'" role="dialog">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> <b>Duplicate Data's</b> </h5>
        <button type="button" class="close" (click)="isModalShow=false;" data-dismiss="modal" (click)="cencel()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive p-0 text-center" style="height: 350px;overflow-y: scroll;">
          <table class="table table-striped table-bordered table-head-fixed">
            <thead class="popuphead">
              <tr>
                <th>SL.NO</th>
                <th>Name</th>
                <th>InvoiceNo</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody *ngIf="this.entrydata.length > 0; else noDataFound">
              <tr *ngFor="let same of this.entrydata; let i =index;">
                <td>{{ i+1 }}</td>
                <td>{{ same.vendorName}}</td>
                <td>{{ same.invoiceNo}}</td>
                <td>{{ same.invoiceAmount}}</td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="saveListData()" data-dismiss="modal">Ok</button>
        <button type="button" class="btn btn-danger" (click)="cencel()" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<app-amdds-footer></app-amdds-footer>

<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>