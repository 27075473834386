<app-amdds-header></app-amdds-header>
<nav class="navbar navbar-expand-lg navbar-light mt-5" style="background-color:darkgray">
  <div class="container-fluid">
    <a class="navbar-brand">Scan Details</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mx-4 topnav" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto ">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center"><b>Scan Entry</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-payment"><b>Scan Payment Details</b></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
            routerLink="/scan-center-doctor-payment"><b>Scan Doctor Payment</b></a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container">
  <div class="row py-5"></div>
  <div class="container row ms-3 mt-3">
    <div class="col-lg-2 mt-3">
      <div class="form-group">
        <input type="checkbox" (click)="updateScanFilter($event, 'scanDoctor')" [checked]="scanDoctorFees === 'scanDoctor'">
        <label class="ms-1">Scan Doctor Fees</label>
      </div>
    </div>
    <div class="col-lg-2 mt-3">
      <div class="form-group">
        <input type="checkbox" (click)="updateScanFilter($event, 'refDoctor')" [checked]="refDoctorFees === 'refDoctor'">
        <label class="ms-1">Ref Doctor Fees</label>
      </div>
    </div>
    <div class="col-lg-2 mt-3">
      <div class="form-group">
        <input type="checkbox" (click)="updateScanFilter($event, 'nikhithaScan')" [checked]="nikhithaScanFees === 'nikhithaScan'">
        <label class="ms-1">Nikhitha Scan Fees</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input type="date" id="fromDate" class="form-control" (change)="onDate()">
        <label for="fromDate">From Date:</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input type="date" id="toDate" class="form-control" (change)="onDate()">
        <label for="toDate">To Date:</label>
      </div>
    </div>
  </div>

  
  <div class="row">
      <div class="container">
        <div class="table-responsive">
          <table class="table">
            <thead class="rounded">
              <tr class="sticky-top">
                <th>No</th>
                <th>Doctor Type</th>
                <th>Doctor Fees</th>
                <th>Scan Date</th>
                <th>Paid Date</th>
                <th>Received Type</th>
                <th>Commands</th>
              </tr>
            </thead>
            <tbody *ngIf="this.getFilterDoctorFees.length > 0; else noDataFound">
              <tr *ngFor="let fees of getFilterDoctorFees| filter: searchText; let i = index">
                <td>{{i + 1}}</td>
                <td>{{fees.referenceType}}</td>
                <td>{{fees.scanFees | currency:'INR':''}}</td>
                <td>{{fees.scanDate | date : "dd-MM-yyyy"}}</td>
                <td>{{fees.paidDate | date : "dd-MM-yyyy"}}</td>
                <td>{{fees.paymentType}}</td>
                <td>{{fees.adminComments}}</td>
              </tr>
            </tbody>
            <ng-template #noDataFound>
              <tr>
                <td colspan="6" class="text-center">Data Not Found</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
<ngx-spinner>
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>

<app-amdds-footer></app-amdds-footer>