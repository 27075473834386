import { Component, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { LoginResponseModel } from '@/model/login-response-model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isPasswordVisible: any;
  userDetails: LoginResponseModel;
  spinner: any;

  togglePasswordVisibility() {
    const passwordShow = document.getElementById(
      'password'
    ) as HTMLInputElement;
    if (passwordShow.type == 'password') {
      passwordShow.type = 'text';
    } else {
      passwordShow.type = 'password';
    }
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  @HostBinding('class') class = 'login-box';
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public loginErrors = {
    username: '',
    password: '',
  };
  username: string;
  password: string;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
  }

  async loginByAuth() {
    // this.isAuthLoading = true;

    this.appService.loginByAuth(this.loginForm);
  }

  async loginByGoogle() {
    this.isGoogleLoading = true;
    await this.appService.loginByGoogle();
    this.isGoogleLoading = false;
  }

  async loginByFacebook() {
    this.isFacebookLoading = true;
    await this.appService.loginByFacebook();
    this.isFacebookLoading = false;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }

  onSubmit() {

    this.formValidation();
  }

  formValidation() {
    this.loginErrors.username = '';
    this.loginErrors.password = '';
    let hasError = false;

    if (this.loginForm.get('username')?.invalid) {
      this.loginErrors.username = "Username is Required";
      hasError = true;
    }

    if (this.loginForm.get("password")?.invalid) {
      if (this.loginForm.get("password").errors.required) {
        this.loginErrors.password = "Password is Required";
        hasError = true;
      }
    }
    // if(this.loginForm.get('password').errors.pattern){
    //   this.toastr.error('Error: Password atleast 8 characters');

    // }


    //  }

    if (!hasError) {
      this.loginByAuth();
    }
  }
}
