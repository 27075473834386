export class dailyDoctors{
    forEach(arg0: (value: any, index: any) => void) {
        throw new Error('Method not implemented.');
    }
    splice(index: any, arg1: number) {
        throw new Error('Method not implemented.');
    }
    amFormID:string = "";
    amFormDate:string = "";
    amDoctorName:string = "";
    amPatientCount:string = "";
}