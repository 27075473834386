import { Component, OnInit } from "@angular/core";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AddStockDetails } from "@/model/addStockDetails";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-stock-expiry-list",
  templateUrl: "./stock-expiry-list.component.html",
  styleUrls: ["./stock-expiry-list.component.scss"]
})
export class StockExpiryListComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dailyStockDetails: AddStockDetails[] = [];
  filterStockDetails: AddStockDetails[] = [];
  getStockDetails: AddStockDetails[] = [];
  currentDate: any;
  getDate: string = "";
  searchText: any;
  isViewShow = false;
  formattedDate: any;
  totalAmounts: string = ""
  expiryDays = [15, 30, 45, 60, 75, 90];
  selectedExpiryDays: { [key: number]: boolean } = {};

  constructor(private apiService: AppService, private datePipe: DatePipe, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    const currentDate = new Date();
    this.currentDate = this.datePipe.transform(currentDate, "yyyy-MM-dd") ?? "";
  }


  ngOnInit(): void {
    this.getDailyStock(this.currentDate);
    const yourDate = this.currentDate;
    this.formattedDate = new Date()
  }



  getDailyStock(date: string) {
    this.spinner.show();
    this.apiService.getDailyStockDetails(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.getStockDetails = data.sort((a, b) => {
          const expiryDateA = new Date(a.expiryDate);
          const expiryDateB = new Date(b.expiryDate);
          const monthA = expiryDateA.getMonth();
          const yearA = expiryDateA.getFullYear();
          const monthB = expiryDateB.getMonth();
          const yearB = expiryDateB.getFullYear();
          if (yearA !== yearB) {
            return yearA - yearB;
          } else {
            return monthA - monthB;
          }
        });
        var local: AddStockDetails[] = []
        const currentDate = new Date();
        const thirtyDaysLater = new Date();
        thirtyDaysLater.setDate(currentDate.getDate() + 30);
        let totalAmounts: number = 0;
        for (let stock of this.getStockDetails) {
          const expiryDateObj = new Date(stock.expiryDate);
          if (expiryDateObj >= currentDate && expiryDateObj <= thirtyDaysLater) {
            local.push(stock);
            totalAmounts += Number(stock.totalAmount) || 0;
            this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
        }
        this.dailyStockDetails = local;
        this.filterStockDetails = this.dailyStockDetails;
        this.spinner.hide();
      }, (err: any) => {
        this.spinner.hide();
        console.log("something went wrong :", err);
        this.toastr.error("Something went wrong , Please try again later....")
      });
  }

  getExpiry() {
    const currentDate = new Date();
    const local: AddStockDetails[] = [];
    let filterApplied = false;
    const isAnyExpirySelected = Object.values(this.selectedExpiryDays).some(value => value);
    let totalAmounts: number = 0;
    for (let stock of this.getStockDetails) {
      const expiryDateObj = new Date(stock.expiryDate);
      if (isAnyExpirySelected) {
        for (let days in this.selectedExpiryDays) {
          if (this.selectedExpiryDays[days]) {
            const futureDate = new Date(currentDate);
            futureDate.setDate(currentDate.getDate() + parseInt(days));
            if (expiryDateObj <= futureDate && expiryDateObj >= currentDate) {
              local.push(stock);
              totalAmounts += Number(stock.totalAmount) || 0;
              this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
              filterApplied = true;
              break;
            }
          }
        }
      } else {
        if (expiryDateObj > currentDate) {
          this.filterStockDetails = this.dailyStockDetails;
          for (let expiry of this.filterStockDetails) {
            totalAmounts += Number(expiry.totalAmount) || 0;
            this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          }
          return
        }
      }
    }
    this.filterStockDetails = local;
  }

  expiryDetails() {
    const currentDate = new Date();
    let totalAmounts: number = 0;
    this.filterStockDetails = this.getStockDetails.filter(stock => {
      const expiryDateObj = new Date(stock.expiryDate);
      return expiryDateObj <= currentDate;
    }).sort((a, b) => {
      const expiryDateA = new Date(a.expiryDate);
      const expiryDateB = new Date(b.expiryDate);
      const monthA = expiryDateA.getMonth();
      const yearA = expiryDateA.getFullYear();
      const monthB = expiryDateB.getMonth();
      const yearB = expiryDateB.getFullYear();
      if (yearA !== yearB) {
        return yearA - yearB;
      } else {
        return monthA - monthB;
      }
    });
    for (let expiry of this.filterStockDetails) {
      totalAmounts += Number(expiry.totalAmount) || 0;
      this.totalAmounts = totalAmounts.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }

}
