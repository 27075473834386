import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NewJoinForms } from "@/model/new_JoinForms";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { PatientCount } from "@/model/PatientCount";
import { DoctorName } from "@/model/DoctorName";
import { dailyDoctors } from "@/model/dailyDoctors";
import { AddStockDetails } from "@/model/addStockDetails";
import { StockPurchaseDetails } from '@/model/stockPurchaseDetails';
import { addVendor } from '@/model/addVendor';
import { LoginRequest } from '@/model/login-request';
import { LoginResponseModel } from '@/model/login-response-model';
import { LocalDataService } from './local-data.service';
import { vendorDetails } from '@/model/vendorDetails';
import { ScanCenterDetails } from '@/model/scenCenterDetails';
import { scanDoctorPayments } from '@/model/scanDoctorPayments';
import { LabTestDetails } from '@/model/labDetails/labTestDetails';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user: any = null;
  userDetails: LoginResponseModel
  //  endpoint = "http://localhost:8085"
  //  endpoint = "http://136.233.82.212:8080/amdds-ws"
  endpoint = "https://tomcat3.varnik.cloud:8443/amdds-ws"
  constructor(private router: Router, private toastr: ToastrService, private http: HttpClient, private localData: LocalDataService) {
  }

  // async loginByAuth({email, password}) {
  //   try {
  //     const token = await Gatekeeper.loginByAuth(email, password);
  //     localStorage.setItem('token', token);
  //     await this.getProfile();
  //     this.router.navigate(['/']);
  //     this.toastr.success('Login success');
  //   } catch (error) {
  //     this.toastr.error(error.message);
  //   }
  // }

  async loginByAuth(loginform: any) {
    const username = loginform.get("username").value;
    const password = loginform.get("password").value;

    let loginRequest = new LoginRequest();
    loginRequest.username = username;
    loginRequest.password = password;

    try {
      const add_url = this.endpoint + "/Amdds/auth/login";
      //const add_url = 'http://127.0.0.1:8084/auth/api';
      const body = JSON.stringify(loginRequest);
      const headers = {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };

      let status = 502;
      this.user = await this.http
        .post<LoginResponseModel>(add_url, body, headers)
        .subscribe(
          (data) => {
            if (data != null) {
              localStorage.setItem("authHeader", String(data.jwtToken));
              localStorage.setItem("userType", String(data.loginDetails.userType));
              this.router.navigate(["dash"]);
              this.toastr.success("Login Successfully");
            }
          },
          (error) => {
            console.error(error);
            this.toastr.error("User Name or Password is Wrong");
          }
        );
    } catch (error) {
      console.log(error);
      this.toastr.error("Network Error.Try again Later");
    }
  }


  async registerByAuth({ email, password }) {
    try {
      // const token = await Gatekeeper.registerByAuth(email, password);
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByGoogle() {
    try {
      // const token = await Gatekeeper.loginByGoogle();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      // const token = await Gatekeeper.registerByGoogle();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      // const token = await Gatekeeper.loginByFacebook();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      // const token = await Gatekeeper.registerByFacebook();
      const token = String(this.localData.getHeaders())
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async getProfile() {
    try {
      // this.user = await Gatekeeper.getProfile();
      this.user = String(this.localData.getHeaders())
    } catch (error) {
      this.logout();
      throw error;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('gatekeeper_token');
    localStorage.removeItem('authHeader');
    localStorage.removeItem('newJoiningId');
    localStorage.removeItem('employeeType');
    localStorage.removeItem('newEmploeeName');
    this.user = null;
    this.router.navigate(['/']);
  }

  getHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://amdds.varnik.cloud"
    });
  }

  getJoinDetails() {
    return this.http.get<NewJoinForms[]>(
      this.endpoint + "/Amdds/getAllEmployees",
      { headers: this.localData.getHeaders() }
    )
  }

  /* get New Join ID */

  getJoinID() {
    return this.http.get<NewJoinForms[]>(
      this.endpoint + "/Amdds/getnewJoinId",
      { headers: this.localData.getHeaders() }
    )
  }

  /*Save NewJoining Details */

  saveNewJoining(userForms: FormGroup) {
    return this.http.post<NewJoinForms[]>(
      this.endpoint + "/Amdds/newJoinForms", userForms,
      { headers: this.localData.getHeaders() }
    )
  }

  fileUpload(formData: FormData, NewJoinId: string, FileName: string) {
    return this.http.post<String[]>(
      this.endpoint + "/Amdds/fileUpload/upload?newjoin_id=" + NewJoinId + "&fileName=" + FileName, formData,
      { headers: this.localData.getHeaders() }
    )
  }

  fileGet(id: string, fileName: string) {
    return this.http.get(
      this.endpoint + "/Amdds/getDocument/" + id + "/" + fileName,
      { headers: this.localData.getHeaders(), responseType: 'blob' }
    )
  }
  getDoctorId() {
    return this.http.get<PatientCount[]>(
      this.endpoint + "/Amdds/getDoctorId",
      { headers: this.localData.getHeaders() }
    )
  }

  addBulkDetails(userForm: FormGroup) {
    return this.http.post<PatientCount[]>(
      this.endpoint + "/Amdds/addPatientBulk", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  saveNewDoctor(userForms: FormGroup) {
    return this.http.post<DoctorName>(
      this.endpoint + "/Amdds/addDoctor", userForms,
      { headers: this.localData.getHeaders() }
    )
  }

  getAllDoctorNames() {
    return this.http.get<DoctorName[]>(
      this.endpoint + "/Amdds/getDoctor",
      { headers: this.localData.getHeaders() }
    )
  }

  getAllPatientDeatils() {
    return this.http.get<PatientCount[]>(
      this.endpoint + "/Amdds/getPatient",
      { headers: this.localData.getHeaders() }
    )
  }

  getDateFetch() {
    return this.http.get<PatientCount>(
      this.endpoint + "/Amdds/getPatient",
      { headers: this.localData.getHeaders() }

    );
  }

  getDailyDoctor(Pdate: string) {
    return this.http.get<dailyDoctors[]>(
      this.endpoint + "/Amdds/getDailyDoctor/" + Pdate,
      { headers: this.localData.getHeaders() }
    )
  }

  addStockDetails(stock: any) {
    return this.http.post<AddStockDetails[]>(
      this.endpoint + "/Amdds/addDailyStocks", stock,
      { headers: this.localData.getHeaders() }
    )
  }

  getDailyStockDetails(date: string) {
    return this.http.get<AddStockDetails[]>(
      this.endpoint + "/Amdds/getDailyStockDetails/" + date,
    )
  }





  excelFileUpload(formData: any) {
    return this.http.post<StockPurchaseDetails[]>(
      this.endpoint + "/Amdds/api/hospital_stock/addAll", formData,
      { headers: this.localData.getHeaders() }
    )
  }

  gethospitalStock() {
    return this.http.get<StockPurchaseDetails[]>(
      this.endpoint + "/Amdds/api/hospital_stock/getAll",
      { headers: this.localData.getHeaders() }
    )
  }
  updateAdminStatus(stockId: number) {
    return this.http.put<StockPurchaseDetails>(
      this.endpoint + "/Amdds/api/hospital_stock/updateAdminStatus/ " + stockId, '',
      { headers: this.localData.getHeaders() }
    )
  }

  saveVendor(usersform: UntypedFormGroup) {
    return this.http.post<addVendor[]>(
      this.endpoint + "/Amdds/api/vendor/add", usersform,
      { headers: this.localData.getHeaders() }
    );
  }

  updateDocterStatus(stockId: number) {
    return this.http.put<StockPurchaseDetails>(
      this.endpoint + "/Amdds/api/hospital_stock/update_doctor_status/" + stockId, '',
      { headers: this.localData.getHeaders() }
    )
  }

  updateAmoutDocterStatus(usersform : any) {
    return this.http.put<StockPurchaseDetails>(
      this.endpoint + "/Amdds/api/hospital_stock/update_doctor_status/",  usersform,
      { headers: this.localData.getHeaders() }
    )
  }

  getAgingDetail() {
    return this.http.get<StockPurchaseDetails[]>(
      this.endpoint + "/Amdds/api/hospital_stock/getByAging"
    )
  }

  updateTransactionID(form: FormGroup) {
    return this.http.put<StockPurchaseDetails>(
      this.endpoint + "/Amdds/api/hospital_stock/updateTransactionDetails", form,
      { headers: this.localData.getHeaders() }
    )
  }

  getSameStockDetails() {
    return this.http.get<AddStockDetails[]>(
      this.endpoint + "/Amdds/getDailyStockDetails/getLast7days",
      { headers: this.localData.getHeaders() }
    )
  }

  //bulkUpdate AdminStatus in oldInvoice

  updateBulkStatus(userForm: number[]) {
    return this.http.put<StockPurchaseDetails[]>(
      this.endpoint + "/Amdds/api/hospital_stock/updateAdminStatus/bulk", userForm,
      { headers: this.localData.getHeaders() }
    )
  }

  //Get Hospital Vendor Details

  getVendorDetails() {
    return this.http.get<vendorDetails[]>(
      this.endpoint + "/Amdds/api/vendor/getAll",
      { headers: this.localData.getHeaders() }
    )
  }

  //Description Based Details Get

  getAmDailyDescripyion(description: string) {
    return this.http.get<AddStockDetails[]>(
      this.endpoint + "/Amdds/getOtherDailyStockDetails/" + description,
    )
  }
  
  //Save Scan Details
  saveScanDetails(userForm : any){
    return this.http.post<ScanCenterDetails>(
      this.endpoint + "/api/scan-details/save" , userForm ,
      { headers: this.localData.getHeaders() }
    )
  }

  getScanDetailsDateBased(date: string) {
    return this.http.get<ScanCenterDetails[]>(
      this.endpoint + "/api/scan-details/getByDate/" + date,
      { headers: this.localData.getHeaders() }
    )
  }

getAllScanDetails(){
  return this.http.get<ScanCenterDetails[]>(
    this.endpoint + "/api/scan-details/getAll",
    { headers: this.localData.getHeaders() }
  )
}

saveScanPaymentDetails(userForm : any){
  return this.http.post<scanDoctorPayments[]>(
    this.endpoint + "/api/doctor-fees/scan-details/save", userForm ,
    { headers: this.localData.getHeaders() }
  )
}

getScanDoctorPayment(){
  return this.http.get<scanDoctorPayments[]>(
    this.endpoint + "/api/doctor-fees/scan-details/getAll" , 
    {headers: this.localData.getHeaders() }
  )
}

getScanDoctorPaymentByDate(date: string){
  return this.http.get<scanDoctorPayments[]>(
    this.endpoint + "/api/doctor-fees/scan-details/getByPaidDate/" + date ,
    {headers : this.localData.getHeaders()}
  )
}

//Save Lab Test Details 

saveLabTestDetails(formData : FormGroup){
  return this.http.post<LabTestDetails>(
    this.endpoint + "/api/labtest/save" , formData,
    {headers : this.localData.getHeaders()}
  )
}

//Get All Lab Test Details

getAllLabTestDetails(){
  return this.http.get<LabTestDetails[]>(
    this.endpoint + "/api/labtest/getAll",
    {headers : this.localData.getHeaders()}
  )
}

//Update Lab Test Payment Details

updateLabPaymentDetails(jsonData: any){
  return this.http.put(
    this.endpoint + "/api/labtest/update", jsonData,
    {headers : this.localData.getHeaders()}
  )
}

//Update Scan Payment Details

updateScanPaymentStatus(jsonData: any){
  return this.http.put(
    this.endpoint + "/api/scan-details/updatePaymentStatus", jsonData,
    {headers : this.localData.getHeaders()}
  )
}


}
