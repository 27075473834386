import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class LocalDataService {
  
    constructor() { }
    
    getHeaders() {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.getToken(),
        'Access-Control-Allow-Origin': '*'
      });
    }
  
  getToken() {
      return localStorage.getItem("authHeader")
  }
  }
  